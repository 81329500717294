import PropTypes from 'prop-types'
import React from 'react'
import CompactHeader from '../../components/CompactHeader'
import './CompactLayout.scss'
import '../../styles/core.scss'

export const CompactLayout = ({ children }) => (
  <div>
    <div
      className="core-layout__viewport"
      style={{ height: '100vh', width: '100%', overflowY: 'auto' }}>
      {children}
    </div>
  </div>
)

CompactLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CompactLayout
