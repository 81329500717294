import jwt from 'jsonwebtoken'
function readCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export default class SecureRoute {

  constructor (store) {
    this.store = store
  }

  onEnter (nextState, replace) {
    const user = readCookie('accessToken')
    if (user) {
      this.store.dispatch({ type: 'GLOBAL_SET_USER', user: jwt.decode(user) })
    }

    if (!user) {
      // Use window.location here to force the request to go to the server. The server
      // handles "/users/authorize" will check windows and other auth before redirecting
      // to the local login page.
      replace('/welcome')
    }
  }
}
