import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Icon from '~/components/Icon'
import VersionNumberBadge from '~/components/VersionNumberBadge'
import AssetGroupItem from './AssetGroupItem'

export default class AssetGroup extends React.Component {
  render() {
    if (
      !Array.isArray(this.props.asset.assets) ||
      this.props.asset.assets.length === 0
    ) {
      return null
    }

    const assetInfo = this.props.asset.assets[0]

    return (
      <div className="px-1 py-2">
        <div
          className="d-flex justify-content-between"
          data-toggle={this.props.dataParent ? 'collapse' : undefined}
          data-target={
            this.props.dataParent
              ? `#collapse-${this.props.asset.commitHash}`
              : undefined
          }>
          <div>
            <Icon className="fal fa-code-branch fa-fw mr-2" />
            {assetInfo.versionString}
            {this.renderAssociatedVersions(assetInfo)}
          </div>
          <div>
            <Icon className="fal fa-code-commit fa-fw mr-2" />
            {this.renderCommitHash()}
          </div>
        </div>
        <div
          id={`collapse-${this.props.asset.commitHash}`}
          className={this.props.dataParent ? 'collapse' : undefined}
          data-parent={this.props.dataParent}>
          <ul className="mb-0 pl-3">
            {this.props.asset.assets
              .filter((a) => a.binaryUrl !== '')
              .map((a) => (
                <AssetGroupItem
                  key={`asset-detail-${a._id}`}
                  details={a}
                  showRemoveButton={
                    this.props.onRemove &&
                    (!a.versions || a.versions.length === 0)
                  }
                  onRemove={(e) => {
                    if (this.props.onRemove) {
                      this.props.onRemove(a)
                    }
                  }}
                />
              ))}
          </ul>
        </div>
      </div>
    )
  }

  renderCommitHash() {
    if (!this.props.asset.commitHash) {
      return <span>No commit hash</span>
    } else {
      return (
        <Link
          to={`https://github.com/${this.props.projectOwner}/${
            this.props.projectName
          }/commit/${this.props.asset.commitHash}`}
          className="text-info"
          target="_blank"
          onClick={(e) => {
            e.stopPropagation()
          }}>
          {this.props.asset.commitHash.substr(0, 7)}
        </Link>
      )
    }
  }

  renderAssociatedVersions(assetInfo) {
    if (!assetInfo.versions || assetInfo.versions.length === 0) {
      return null
    }

    const versions = assetInfo.versions.map((v) => {
      return (
        <Link
          key={v._id}
          className="mr-2"
          to={`${this.props.projectOwner}/${this.props.projectName}/v/${
            v.version
          }`}>
          <VersionNumberBadge version={v} />
        </Link>
      )
    })

    return (
      <span className="ml-3">
        <Icon className="fal fa-link fa-fw mr-2" />
        {versions}
      </span>
    )
  }
}

AssetGroup.propTypes = {
  asset: PropTypes.object.isRequired,
  projectOwner: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  dataParent: PropTypes.string,
  onRemove: PropTypes.func
}
