import Icon from '~/components/Icon'
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import AssetGroupSelectionList from './AssetGroupSelectionList'
import SingleAssetSelectionList from './SingleAssetSelectionList'

export default class AssignAssetsModal extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.projectOwner !== this.props.projectOwner &&
      nextProps.projectName !== this.props.projectName &&
      nextProps.version !== this.props.version
    ) {
      this.props.getAssets(
        nextProps.projectOwner,
        nextProps.projectName,
        nextProps.version
      )
    }
  }
  render() {
    if (!this.props.isOpen) {
      return null
    } else {
      const projectVersionText = `${this.props.projectOwner}/${
        this.props.projectName
      }@${this.props.version.version}`
      return (
        <Modal
          size="lg"
          isOpen={this.props.isOpen}
          toggle={this.props.toggleVisibility}>
          <ModalHeader
            style={{ fontSize: '1.2rem' }}
            toggle={this.props.toggleVisibility}>
            Assign assets to{' '}
            <span className="text-info">{projectVersionText}</span>
          </ModalHeader>
          <ModalBody>{this.renderAssets()}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-outline-secondary"
              onClick={this.props.toggleVisibility}>
              <Icon className="fal fa-times fa-fw" /> Cancel
            </button>
            <button
              className="btn btn-success"
              disabled={!this.props.selectedAsset}
              onClick={() => {
                this.props.assignAssets(
                  this.props.selectedAsset,
                  this.props.projectOwner,
                  this.props.projectName,
                  this.props.version
                )
              }}>
              <Icon className="fal fa-save fa-fw" /> Assign Assets
            </button>
          </ModalFooter>
        </Modal>
      )
    }
  }

  renderAssets() {
    if (this.props.enableAssetTags) {
      return (
        <AssetGroupSelectionList
          items={this.props.assets}
          selectedItem={this.props.selectedAsset}
          setSelected={this.props.setSelectedAsset}
          projectOwner={this.props.projectOwner}
          projectName={this.props.projectName}
        />
      )
    } else {
      return (
        <SingleAssetSelectionList
          items={this.props.assets}
          selectedItem={this.props.selectedAsset}
          setSelected={this.props.setSelectedAsset}
          projectOwner={this.props.projectOwner}
          projectName={this.props.projectName}
        />
      )
    }
  }
}

AssignAssetsModal.propTypes = {
  isOpen: PropTypes.bool,
  projectOwner: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  version: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  selectedAsset: PropTypes.object,
  assignAssets: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  enableAssetTags: PropTypes.bool.isRequired
}
