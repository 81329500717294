import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import PropTypes from 'prop-types'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import { rootSaga } from './sagas'
import { injectSaga } from '../../store/sagas'
import Notifications from '../Notifications'

class AppContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.any.isRequired,
    store: PropTypes.object.isRequired
  }

  componentWillMount() {}

  constructor(props) {
    super(props)

    injectSaga('appSaga', rootSaga)
  }

  render() {
    const { history, routes, store } = this.props
    return (
      <Provider store={store}>
        <div>
          <Notifications />
          <Router history={history} children={routes} />
        </div>
      </Provider>
    )
  }
}
export default hot(module)(AppContainer)
