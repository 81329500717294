import { injectReducer } from '../../store/reducers'
import { injectSaga } from '../../store/sagas'
import LandingPageContainer from './containers/LandingPageContainer'
import { default as reducer, rootSaga } from './modules/index'

function readCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

// Sync route definition
export default class LandingPageRoute {
  constructor(store, path) {
    this.path = path
    this.getComponent = async (nextState, cb) => {
      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, {
        key: 'landing/main',
        reducer: reducer
      })

      // /*  Run the rootSaga on the sagaMiddleware with key of 'counter'  */
      injectSaga('landing/main', rootSaga)

      cb(null, LandingPageContainer)
    }
  }

  onEnter(nextState, replace) {
    const user = readCookie('accessToken')

    if (user) {
      // Use window.location here to force the request to go to the server. The server
      // handles "/users/authorize" will check windows and other auth before redirecting
      // to the local login page.
      replace('/')
    }
  }
}
