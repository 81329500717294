import Icon from '../../components/Icon'
import React from 'react'
import cookieManager from '../../utils/cookies'
import { Link } from 'react-router'
import './Header.scss'

const QUOTES = [
  {
    text: 'Everything you can imagine is real',
    attribution: 'Pablo Picasso',
    authorInfo: 'https://en.wikipedia.org/wiki/Pablo_Picasso'
  },
  {
    text: 'Action is the foundational key to all success',
    attribution: 'Pablo Picasso',
    authorInfo: 'https://en.wikipedia.org/wiki/Pablo_Picasso'
  },
  {
    text: 'Life is trying things to see if they work',
    attribution: 'Ray Bradbury',
    authorInfo: 'https://en.wikipedia.org/wiki/Ray_Bradbury'
  },
  {
    text: 'Eighty percent of success is showing up',
    attribution: 'Woody Allen',
    authorInfo: 'https://en.wikipedia.org/wiki/Woody_Allen'
  },
  {
    text: 'In the middle of every difficulty lies opportunity',
    attribution: 'Albert Einstein',
    authorInfo: 'https://en.wikipedia.org/wiki/Albert_Einstein'
  },
  {
    text: 'Anyone who has never made a mistake has never tried anything new',
    attribution: 'Albert Einstein',
    authorInfo: 'https://en.wikipedia.org/wiki/Albert_Einstein'
  },
  {
    text:
      'Big jobs usually go to the [people] who prove their ability to outgrow small ones',
    attribution: 'Ralph Emerson',
    authorInfo: 'https://en.wikipedia.org/wiki/Ralph_Waldo_Emerson'
  },
  {
    text:
      'Whatever you can do, or dream you can, begin it. Boldness has genius, power, and magic in it',
    attribution: 'Goethe',
    authorInfo: 'https://en.wikipedia.org/wiki/Johann_Wolfgang_von_Goethe'
  },
  {
    text: 'It’s kind of fun to do the impossible',
    attribution: 'Walt Disney',
    authorInfo: 'https://en.wikipedia.org/wiki/Walt_Disney'
  },
  {
    text:
      'Most obstacles melt away when we make up our minds to walk boldly through them',
    attribution: 'Orison Swett Marden',
    authorInfo: 'https://en.wikipedia.org/wiki/Orison_Swett_Marden'
  },
  {
    text: 'Make yourself proud',
    attribution: 'Peter W. Smith',
    authorInfo: 'https://en.wikipedia.org/wiki/Peter_W._Smith'
  },
  {
    text: 'You are never too old to set another goal or to dream a new dream',
    attribution: 'C. S. Lewis',
    authorInfo: 'https://en.wikipedia.org/wiki/C._S._Lewis'
  },
  {
    text:
      'Start where you are, with what you have, and that will always lead you into something greater',
    attribution: 'Mary Manin Morrissey',
    authorInfo: 'https://en.wikipedia.org/wiki/Living_Enrichment_Center'
  },
  {
    text:
      'Great things are not done by impulse, but by a series of small things brought together',
    attribution: 'Vincent Van Gogh',
    authorInfo: 'https://en.wikipedia.org/wiki/Vincent_van_Gogh'
  },
  {
    text:
      'There is real magic in enthusiasm. It spells the difference between mediocrity and accomplishment',
    attribution: 'Norman Vincent Peale',
    authorInfo: 'https://en.wikipedia.org/wiki/Norman_Vincent_Peale'
  }
]

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quote: QUOTES[Math.floor(Math.random() * QUOTES.length)]
    }
  }

  componentWillMount() {
    this.quoteInterval = setInterval(this.updateQuote.bind(this), 60 * 1000)
  }

  componentWillUnmount() {
    if (this.quoteInterval) {
      clearInterval(this.quoteInterval)
      this.quoteInterval = undefined
    }
  }

  logout() {
    cookieManager.deleteCookie('accessToken')
    window.location.reload(true)
  }

  updateQuote() {
    this.setState({
      quote: QUOTES[Math.floor(Math.random() * QUOTES.length)]
    })
  }
  render() {
    const message = this.state.quote
    const quoteContent = this.props.hideQuote ? null : (
      <div
        className="container"
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '70%'
        }}>
        <h1
          className="display-4"
          style={{ fontSize: '1.5rem', lineHeight: 1.0 }}>
          <blockquote>
            <p className="mt-2">{`${message.text}.`.replace('..', '.')}</p>
            <footer style={{ fontSize: '1.25rem' }}>
              -
              <a
                style={{ textDecoration: 'none' }}
                className="text-muted"
                target="_blank"
                href={message.authorInfo}>
                {message.attribution}{' '}
                <i
                  style={{ fontSize: '0.6rem', opacity: 0.7 }}
                  className="fal fa-info-circle"
                />
              </a>
            </footer>
          </blockquote>
        </h1>
      </div>
    )

    const navClassName =
      'navbar navbar-inverse navbar-expand-lg navbar-dark bg-dark'
    return (
      <div className={this.props.sticky ? 'sticky-top' : ''}>
        <nav className={navClassName}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <Icon className="fal fa-bars" />
          </button>
          <Link className="navbar-brand text-info-bright" to="/">
            <Icon className="fal fa-fw fa-gift" /> Parcel
          </Link>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/">
                  <Icon className="fal fa-home fa-fw" /> Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/releaseNotes">
                  <Icon className="fal fa-bullhorn fa-fw" /> What's New
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/docs">
                  <Icon className="fal fa-book fa-fw" /> Docs
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-nav">
            <a
              href="#"
              role="button"
              className="nav-link"
              onClick={this.logout}>
              <Icon className="fal fa-sign-out fa-fw" /> Logout
            </a>
          </div>
        </nav>
        <div
          className="d-none d-md-block"
          style={{ backgroundColor: '#f0f0f0' }}>
          {quoteContent}
        </div>
      </div>
    )
  }
}
