import Octicon from 'react-octicon'
import React from 'react'
import moment from 'moment'

export default class PullRequest extends React.PureComponent {
  render () {
    const iconColor = this.props.state === 'open' ? 'text-purple' : 'text-success'
    const iconType = this.props.state === 'open' ? 'git-pull-request' : 'git-merge'
    return (
      <div className='list-group-item list-group-item-action' style={{ display:'flex', cursor: 'default' }} onClick={(e) => {
        if (e.target.tagName.toLowerCase() !== 'a') {
          this.props.setSelected(!this.props.selected)
        }
      }} >
        <div className='mr-3'>
          <input type='checkbox' checked={this.props.selected} onChange={(e) => { this.props.setSelected(e.target.checked) }} />
        </div>
        <div className='mr-3' >
          <Octicon className={iconColor} name={iconType} />
        </div>
        <div>
          <h5 className='mb-0'>
            <a className='text-muted hover-success' style={{ fontSize: '1.2rem' }} href={this.props.html_url} target='_blank'>
              {this.props.title}
            </a>
          </h5>
          {this.props.state === 'open' &&
            this.renderOpenDetails()
          }
          {(this.props.state === 'closed' && this.props.merged_at) &&
            this.renderMergedDetails()
          }
        </div>
      </div>
    )
  }

  renderOpenDetails () {
    const openedFrom = moment().to(this.props.created_at)
    return (
      <div className='text-muted pr-details'>
        #{this.props.number} opened {openedFrom} by
        <a className='hover-link text-muted hover-success' href={this.props.user.html_url} target='_blank'>
          {this.props.user.login}
        </a>
      </div>
    )
  }
  renderMergedDetails () {
    const mergedFrom = moment().to(this.props.merged_at)
    return <div className='text-muted pr-details'>
        #{this.props.number} by <a className='hover-link text-muted hover-success' href={this.props.user.html_url} target='_blank'>
          {this.props.user.login}</a> merged {mergedFrom}
    </div>
  }
  renderClosedDetails () {

  }
}
