import SecureRoute from '../../SecureRoute'
import { injectReducer } from '../../../store/reducers'
import { injectSaga } from '../../../store/sagas'

// Sync route definition
export default class HomeViewRoute extends SecureRoute {
  constructor(store, path) {
    super(store)
    this.path = path
    this.getComponent = async (nextState, cb) => {
      const EditProjectContainer = await import(
        './containers/EditProjectContainer'
      )

      const EditProjectModule = await import('./modules')

      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, {
        key: 'projects/edit',
        reducer: EditProjectModule.default
      })

      // /*  Run the rootSaga on the sagaMiddleware with key of 'counter'  */
      injectSaga('projects/edit', EditProjectModule.rootSaga)

      cb(null, EditProjectContainer.default)
    }
  }
}
