import React from 'react'
import { Link } from 'react-router'
function readCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length))
  }
  return null
}

import './CompactHeader.scss'
const UserName = readCookie('RN_USER_NAME')
export default class Header extends React.Component {
  render () {
    return (
      <nav className='navbar navbar-toggleable-md sticky-top navbar-light bg-faded' style={{ backgroundColor: '#f0f0f0' }} >
        <Link className='navbar-brand text-info' to='/'>Parcel</Link>

        <div className='collapse navbar-collapse' id='navbarText'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link className='nav-link' activeClassName='route--active' to='/'>Home</Link>
            </li>
          </ul>
          <span className='navbar-text'>
            {UserName}
          </span>
        </div>
      </nav>

    )
  }
}

