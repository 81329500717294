@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.projectBreadcrumb {
  background-color: transparent;
  margin-bottom: 0;
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .projectBreadcrumb {
    font-size: 1.2rem;
  }
}