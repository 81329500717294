import React from 'react'
import { getForegroundForColor } from '../../../shared/colorHelper'
export default class NoteTag extends React.PureComponent {
  render () {
    const foreground = getForegroundForColor(this.props.tag.color)
    return <span className={`badge ${this.props.className}`} style={{ backgroundColor: this.props.tag.color, color: foreground }}>
      <div className='py-1'>{this.props.tag.text}</div>
    </span>
  }
}
