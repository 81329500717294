import PropTypes from 'prop-types'
import React from 'react'
import Notifications from 'react-notification-system-redux'
import { connect } from 'react-redux'

class NotificationsContainer extends React.PureComponent {
  render() {
    const style = {
      Containers: {
        tr: {
          top: '56px',
          width: 'auto'
        }
      },

      NotificationItem: {
        DefaultStyle: {
          width: ['fit-content', '-moz-fit-content', '-webkit-fit-content'],
          whiteSpace: 'nowrap',
          padding: '10px 20px 10px 10px'
        }
      }
    }

    return (
      <Notifications notifications={this.props.notifications} style={style} />
    )
  }
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.array
}

const mapStateToProps = (state) => {
  const notifications = state.get('notifications')
  return {
    notifications
  }
}

export default connect(mapStateToProps)(NotificationsContainer)
