import { connect } from 'react-redux'
import { actions } from '../modules'
import { actions as assetActions } from '~/containers/AssignAssetsModal/reducer'
import EditVersion from '../components/EditVersion'

const mapDispatchToProps = {
  ...actions,
  ...assetActions
}

const mapStateToProps = (state, ownProps) => {
  const editVersionState = state.get('versions/edit').toJS()
  return {
    ...editVersionState,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditVersion)
