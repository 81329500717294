import SecureRoute from '../SecureRoute'
import { injectReducer } from '../../store/reducers'
import { injectSaga } from '../../store/sagas'

// Sync route definition
export default class HomeViewRoute extends SecureRoute {
  constructor(store, path) {
    super(store)
    this.path = path || '/'
    this.getComponent = async (nextState, cb) => {
      /*  Webpack - use require callback to define
            dependencies for bundling   */

      const HomeView = await import('./containers/HomeViewContainer')
      const HomeModule = await import('./modules/index')

      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, {
        key: 'home',
        reducer: HomeModule.default
      })

      // /*  Run the rootSaga on the sagaMiddleware with key of 'counter'  */
      injectSaga('home', HomeModule.rootSaga)

      cb(null, HomeView.default)
    }
  }
}
