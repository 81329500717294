// ------------------------------------
// Imports
// ------------------------------------
import $ from 'jquery'
import { put, fork, call, takeLatest } from 'redux-saga/effects'
import { browserHistory } from 'react-router'
import { fromJS } from 'immutable'
import request from '~/utils/request'
import { errorToast } from '~/utils/notifications'

// ------------------------------------
// Constants
// ------------------------------------

const HOME_LOAD_PROJECTS = 'HOME_LOAD_PROJECTS'
const HOME_SET_PROJECTS = 'HOME_SET_PROJECTS'

const HOME_SET_FILTER = 'HOME_SET_FILTER'
const HOME_SET_NEW_PROJECT_FILTER = 'HOME::SET_NEW_PROJECT_FILTER'

const HOME_SET_NEW_PROJECT_VISIBLE = 'HOME_SET_NEW_PROJECT_VISIBLE'

const HOME_FETCH_REPOS = 'HOME_FETCH_REPOS'
const HOME_SET_REPOS = 'HOME_SET_REPOS'
const HOME_SELECT_REPO = 'HOME_SELECT_REPO'
const HOME_CREATE_PROJECT = 'HOME_CREATE_PROJECT'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  loadProjects: () => {
    return {
      type: HOME_LOAD_PROJECTS
    }
  },
  setProjects: (projects) => {
    return {
      type: HOME_SET_PROJECTS,
      projects
    }
  },
  setFilter: (filter) => {
    return {
      type: HOME_SET_FILTER,
      filter
    }
  },
  setNewProjectFilter: (filter) => {
    return {
      type: HOME_SET_NEW_PROJECT_FILTER,
      filter
    }
  },
  fetchRepos: () => {
    return {
      type: HOME_FETCH_REPOS
    }
  },
  setProjectModalVisible: (visible) => {
    return {
      type: HOME_SET_NEW_PROJECT_VISIBLE,
      visible
    }
  },
  setRepos: (repos) => {
    return {
      type: HOME_SET_REPOS,
      repos
    }
  },
  selectRepo: (repo) => {
    return {
      type: HOME_SELECT_REPO,
      repo
    }
  },
  createProject: (repo) => {
    return {
      type: HOME_CREATE_PROJECT,
      repo
    }
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [HOME_LOAD_PROJECTS]: (state, action) => state.set('loading', true),
  [HOME_SET_PROJECTS]: (state, action) =>
    state.set('loading', false).set('projects', fromJS(action.projects)),
  [HOME_SET_FILTER]: (state, action) => state.set('filter', action.filter),
  [HOME_SET_NEW_PROJECT_FILTER]: (state, action) =>
    state.set('newProjectFilter', action.filter),
  [HOME_SET_NEW_PROJECT_VISIBLE]: (state, action) =>
    state.set('newProjectModalVisible', action.visible).set('filter', ''),
  [HOME_SET_REPOS]: (state, action) =>
    state
      .set('repos', fromJS(action.repos))
      .set('reposLoading', false)
      .set('creating', false),
  [HOME_FETCH_REPOS]: (state, action) => state.set('reposLoading', true),
  [HOME_SELECT_REPO]: (state, action) =>
    state.set('selectedRepo', fromJS(action.repo)),
  [HOME_CREATE_PROJECT]: (state, action) =>
    state.set('creating', true).set('newProjectFilter', '')
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  projects: [],
  filter: '',
  newProjectFilter: '',
  repos: [],
  reposLoading: false,
  loading: false,
  creating: false
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
// ------------------------------------
// Sagas
// ------------------------------------

function* loadProjectsAsync(action) {
  const result = yield call(request, '/api/projects')
  if (result.success) {
    const owners = {}
    result.results.forEach((p) => {
      const ownerName = p.owner.login
      if (!owners[ownerName]) {
        owners[ownerName] = p.owner
      }
      if (!owners[ownerName].projects) {
        owners[ownerName].projects = []
      }
      delete p.owner
      owners[ownerName].projects.push(p)
    })

    yield put(actions.setProjects(owners))
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function* fetchReposAsync(action) {
  const result = yield call(request, '/api/repos')
  if (result.success) {
    yield put(actions.setRepos(result.results))
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function* createProjectAsync(action) {
  const repo = action.repo
  const result = yield call(request, '/api/projects', {
    method: 'POST',
    body: JSON.stringify(repo)
  })
  if (result.success) {
    $('.modal-backdrop').remove()
    yield put(actions.loadProjects())
    browserHistory.push(`/${result.project.owner.login}/${result.project.name}`)
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function* watchCreateProject() {
  yield takeLatest(HOME_CREATE_PROJECT, createProjectAsync)
}

function* watchLoadProjects() {
  yield takeLatest(HOME_LOAD_PROJECTS, loadProjectsAsync)
}
function* watchFetchRepos() {
  yield takeLatest(HOME_FETCH_REPOS, fetchReposAsync)
}

export function* rootSaga() {
  yield fork(watchLoadProjects)
  yield fork(watchFetchRepos)
  yield fork(watchCreateProject)
}
