import { connect } from 'react-redux'
import { actions } from '../modules'

import Home from '../components/Home'

const mapStateToProps = (state, ownProps) => {
  console.log('TCL: mapStateToProps -> state', state)
  let params = ownProps.params || {}
  if (!params.tab) {
    params.tab = 'home'
  }

  const docState = state.get('documentation').toJS()
  return {
    ...docState,
    params
  }
}

const mapDispatchToProps = {
  ...actions
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
