
class CookieManager {
  constructor () {
    this.cookies = this.getCookiesFromDocument()
  }

  getCookiesFromDocument () {
    var c = document.cookie.split('; ')
    var cookies = {}

    for (var i = c.length - 1; i >= 0; i--) {
      var C = c[i].split('=')
      cookies[C[0]] = C[1]
    }
    return cookies
  }

  readCookie (name) {
    if (this.cookies) { return this.cookies[name] }
  }

  deleteCookie (name) {
    document.cookie = name + '=; Max-Age=0'
  }

}

const cookieManager = new CookieManager()
export default cookieManager
