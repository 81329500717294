import {
  fromJS
} from 'immutable'

// selectLocationState expects a plain JS object for the routing state
export const selectLocationState = () => {
  let prevRoutingState
  let prevRoutingStateJS

  return (state) => {
    const routingState = fromJS(state.get('routing'))

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState
      prevRoutingStateJS = routingState.toJS()
    }

    return prevRoutingStateJS
  }
}

export const selectGlobalState = () => (state) => state.get('global')
