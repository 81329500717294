import Icon from '~/components/Icon'
import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
export default class VersionDropdown extends React.PureComponent {
  getCreatePrereleaseLink() {
    return (
      <Link
        className="dropdown-item"
        key={`download-${this.props.version.version}`}
        to={`/${this.props.projectOwner}/${this.props.projectName}/versions/v/${
          this.props.version.version
        }/pre`}>
        <Icon className="fal fa-fw fa-plus-square mr-1" /> Create Pre-Release
        Version
      </Link>
    )
  }

  getAssetDownloadLink(a) {
    return (
      <a
        className="dropdown-item"
        key={`download-${this.props.version.version}-${a.name || 'binary'}`}
        target="_blank"
        href={a.binaryUrl}>
        <Icon className="fal fa-fw fa-download mr-1" />
        {`Download ${a.name || 'binary'}`}
      </a>
    )
  }

  getAssignAssetsButton() {
    return (
      <button
        className="dropdown-item"
        key={`assign-assets-${this.props.version.version}`}
        onClick={() => {
          this.props.showAssignAssetsModal(
            this.props.projectOwner,
            this.props.projectName,
            this.props.version
          )
        }}>
        <Icon className="fal fa-fw fa-file-archive" /> Assign Assets
      </button>
    )
  }

  render() {
    const options = []
    const version = this.props.version

    if (!version.prerelease) {
      options.push(this.getCreatePrereleaseLink())
    }
    if (version.assets) {
      version.assets.map((a) => {
        if (a.binaryUrl !== '') {
          options.push(this.getAssetDownloadLink(a))
        }
      })
    }
    if (!version.assets || version.assets.length === 0) {
      options.push(this.getAssignAssetsButton())
    }

    if (options.length > 0) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-link hover-success dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup
          />
          <div className="dropdown-menu">{options}</div>
        </div>
      )
    }
    return null
  }
}

VersionDropdown.propTypes = {
  version: PropTypes.object.isRequired,
  projectOwner: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired
}
