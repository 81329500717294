// ------------------------------------
// Imports
// ------------------------------------
import handlebars from '../../../../static/js/handlebars.min-latest'
import { delay } from 'redux-saga'
import { put, fork, select, call, takeLatest } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import request from '~/utils/request'
import { errorToast, successToast } from '~/utils/notifications'
import { getColorHash } from '../../../../../shared/colorHelper'

const PARCEL_DEMO_ID = '5a98467bd1375d0933b31947'
const PARCEL_DEMO_VERSION = '1.1.1'

// ------------------------------------
// Constants
// ------------------------------------
const PROJECT_SET_NAME = 'PROJECT::SET_NAME'
const PROJECT_SET_DESCRIPTION = 'PROJECT::SET_DESCRIPTION'

const PROJECT_TOGGLE_ASSET_TAGS = 'PROJECT::TOGGLE_ASSET_TAGS'
const PROJECT_EDIT_ASSET_TAG_TEXT = 'PROJECT::EDIT_ASSET_TAG_TEXT'
const PROJECT_SET_ASSET_TAG_TEXT = 'PROJECT::SET_ASSET_TAG_TEXT'
const PROJECT_SET_ASSET_TAG_EDIT_MODE = 'PROJECT::SET_ASSET_TAG_EDIT_MODE'
const PROJECT_EDIT_ASSET_TAG = 'PROJECT::EDIT_ASSET_TAG'
const PROJECT_SET_ASSET_TAG = 'PROJECT::SET_ASSET_TAG'
const PROJECT_SET_ASSET_TAG_DELETE_MODE = 'PROJECT::SET_ASSET_TAG_DELETE_MODE'
const PROJECT_REMOVE_ASSET_TAG = 'PROJECT::REMOVE_ASSET_TAG'

const PROJECT_SET_TAGS_TEXT = 'PROJECT::SET_TAGS_TEXT'
const PROJECT_SET_TAGS_PRIORITY = 'PROJECT::SET_TAGS_PRIORITY'
const PROJECT_SET_TAGS = 'PROJECT::SET_TAGS'
const PROJECT_SET_TAG_EDIT_MODE = 'PROJECT::SET_TAG_EDIT_MODE'
const PROJECT_SET_TAG_DELETE_MODE = 'PROJECT::SET_TAG_DELETE_MODE'
const PROJECT_EDIT_TAG = 'PROJECT::EDIT_TAG'
const PROJECT_EDIT_TAG_TEXT = 'PROJECT::EDIT_TAG_TEXT'
const PROJECT_EDIT_TAG_COLOR = 'PROJECT::EDIT_TAG_COLOR'
const PROJECT_EDIT_TAG_PRIORITY = 'PROJECT::EDIT_TAG_PRIORITY'
const PROJECT_REMOVE_TAGS = 'PROJECT::REMOVE_TAGS'

const PROJECT_SET_LOCALES_TEXT = 'PROJECT::SET_LOCALES_TEXT'
const PROJECT_SET_LOCALES = 'PROJECT::SET_LOCALES'
const PROJECT_REMOVE_LOCALES = 'PROJECT::REMOVE_LOCALES'
const PROJECT_SET_LOCALE_DELETE_MODE = 'PROJECT::SET_LOCALE_DELETE_MODE'
const PROJECT_SET_LOCALE_EDIT_MODE = 'PROJECT::SET_LOCALE_EDIT_MODE'
const PROJECT_EDIT_LOCALE = 'PROJECT::EDIT_LOCALE'
const PROJECT_EDIT_LOCALE_TEXT = 'PROJECT::EDIT_LOCALE_TEXT'

const PROJECT_SET_REPO_URL = 'PROJECT::SET_REPO_URL'
const PROJECT_SET_LOGO_URL = 'PROJECT::SET_LOGO_URL'

const PROJECT_SET_VERSIONS = 'PROJECT::SET_VERSIONS'

const PROJECT_SHOW_ADD_TAG = 'PROJECT::SHOW_ADD_TAG'
const PROJECT_SHOW_ADD_LOCALE = 'PROJECT::SHOW_ADD_LOCALE'
const PROJECT_SHOW_ADD_ASSET_TAG = 'PROJECT::SHOW_ADD_ASSET_TAG'

const PROJECT_SET_SHOW_COLOR_PICKER = 'PROJECT::SET_SHOW_COLOR_PICKER'
const PROJECT_SET_SELECTED_COLOR = 'PROJECT::SET_SELECTED_COLOR'

const PROJECT_SAVE = 'PROJECT::SAVE'
const PROJECT_LOAD = 'PROJECT::LOAD'
const PROJECT_SET = 'PROJECT::SET'

const PROJECT_GET_BRANCHES = 'PROJECT::GET BRANCHES'
const PROJECT_SET_BRANCHES = 'PROJECT::SET BRANCHES'
const PROJECT_SET_WORKING_BRANCH = 'PROJECT::SET WORKING BRANCH'

const PROJECT_SET_GITHUB_RELEASE_CODE = 'PROJECT::SET GITHUB RELEASE CODE'
const PROJECT_SET_RELEASE_PREVIEW = 'PROJECT::SET RELEASE PREVIEW'

const PROJECT_SET_RELEASE_PREVIEW_TAB = 'PROJECT::SET RELEASE TAB'
const PROJECT_SET_RELEASE_PREVIEW_STATE = 'PROJECT::SET RELEASE PREVIEW STATE'
const PROJECT_PREVIEW_RELEASE = 'PROJECT::PREVIEW_RELEASE'
const PROJECT_SELECT_VERSION = 'PROJECT::SELECT_VERSION'
const PROJECT_TOGGLE_PILL = 'PROJECT::TOGGLE_PILL'
const PROJECT_SET_SAVING_STATE = 'PROJECT::SET SAVING STATE'

const PROJECT_SET_HTML_TEMPLATE = 'PROJECT::SET HTML TEMPLATE'
const PROJECT_SELECT_SCOPE = 'PROJECT::SELECT SCOPE'
const PROJECT_TOGGLE_CONTEXT_MODAL = 'PROJECT::TOGGLE CONTEXT MODAL'
const PROJECT_PREVIEW_HTML_TEMPLATE = 'PROJECT::PREVIEW HTML TEMPLATE'
const PROJECT_PREVIEW_HTML_TEMPLATE_SET_PREVIEW =
  'PROJECT::PREVIEW HTML TEMPLATE SET PREVIEW'

const PROJECT_PREVIEW_SET_CONTEXT = 'PROJECT::PREVIEW SET CONTEXT'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  toggleContextModal: () => ({ type: PROJECT_TOGGLE_CONTEXT_MODAL }),
  previewHTMLTemplate: () => ({ type: PROJECT_PREVIEW_HTML_TEMPLATE }),
  setTemplatePreview: (content) => ({
    type: PROJECT_PREVIEW_HTML_TEMPLATE_SET_PREVIEW,
    content
  }),
  setName: (name) => {
    return {
      type: PROJECT_SET_NAME,
      name
    }
  },
  setPreviewNotesContext: (context, isDemo) => {
    return {
      type: PROJECT_PREVIEW_SET_CONTEXT,
      context,
      isDemo
    }
  },
  selectVersion: (version) => {
    return {
      type: PROJECT_SELECT_VERSION,
      version
    }
  },
  setHTMLTemplate: (template) => {
    return {
      type: PROJECT_SET_HTML_TEMPLATE,
      template
    }
  },
  selectScope: (scope) => {
    return {
      type: PROJECT_SELECT_SCOPE,
      scope
    }
  },
  setReleasePreviewTab: (tab) => {
    return {
      type: PROJECT_SET_RELEASE_PREVIEW_TAB,
      tab
    }
  },
  setReleasePreviewState: (state) => {
    return {
      type: PROJECT_SET_RELEASE_PREVIEW_STATE,
      state
    }
  },
  setReleasePreview: (text, html, err) => {
    return {
      type: PROJECT_SET_RELEASE_PREVIEW,
      text,
      html,
      err
    }
  },
  previewRelease: () => {
    return {
      type: PROJECT_PREVIEW_RELEASE
    }
  },
  saveProject: (saveCode) => {
    return {
      type: PROJECT_SAVE,
      saveCode
    }
  },
  setVersions: (versions) => {
    return {
      type: PROJECT_SET_VERSIONS,
      versions
    }
  },
  loadProject: (owner, name) => {
    return {
      type: PROJECT_LOAD,
      owner,
      name
    }
  },
  setDescription: (description) => {
    return {
      type: PROJECT_SET_DESCRIPTION,
      description
    }
  },
  setTagsText: (text) => {
    return {
      type: PROJECT_SET_TAGS_TEXT,
      text
    }
  },
  showAddTag: () => {
    return {
      type: PROJECT_SHOW_ADD_TAG
    }
  },
  setTag: (tag) => {
    return {
      type: PROJECT_SET_TAGS,
      tag
    }
  },
  setTagEditMode: (tag) => {
    return {
      type: PROJECT_SET_TAG_EDIT_MODE,
      tag
    }
  },
  setTagDeleteMode: (tag) => {
    return {
      type: PROJECT_SET_TAG_DELETE_MODE,
      tag
    }
  },
  editTag: (tag, index) => {
    return {
      type: PROJECT_EDIT_TAG,
      tag,
      index
    }
  },
  editTagText: (tag, text) => {
    return {
      type: PROJECT_EDIT_TAG_TEXT,
      tag,
      text
    }
  },
  setTagsPriority: (priority) => {
    return {
      type: PROJECT_SET_TAGS_PRIORITY,
      priority
    }
  },
  editTagPriority: (tag, priority) => {
    return {
      type: PROJECT_EDIT_TAG_PRIORITY,
      tag,
      priority
    }
  },
  editTagColor: (tag, color) => {
    return {
      type: PROJECT_EDIT_TAG_COLOR,
      tag,
      color
    }
  },
  removeTag: (tag) => {
    return {
      type: PROJECT_REMOVE_TAGS,
      tag
    }
  },
  showAddLocale: () => {
    return {
      type: PROJECT_SHOW_ADD_LOCALE
    }
  },
  setLocaleDeleteMode: (locale) => {
    return {
      type: PROJECT_SET_LOCALE_DELETE_MODE,
      locale
    }
  },
  setLocaleEditMode: (locale) => {
    return {
      type: PROJECT_SET_LOCALE_EDIT_MODE,
      locale
    }
  },
  setLocaleText: (text) => {
    return {
      type: PROJECT_SET_LOCALES_TEXT,
      text
    }
  },
  setLocale: (locale) => {
    return {
      type: PROJECT_SET_LOCALES,
      locale
    }
  },
  removeLocale: (locale) => {
    return {
      type: PROJECT_REMOVE_LOCALES,
      locale
    }
  },
  editLocale: (locale, index) => {
    return {
      type: PROJECT_EDIT_LOCALE,
      locale,
      index
    }
  },
  editLocaleText: (locale, text) => {
    return {
      type: PROJECT_EDIT_LOCALE_TEXT,
      locale,
      text
    }
  },
  setRepoUrl: (repoUrl) => {
    return {
      type: PROJECT_SET_REPO_URL,
      repoUrl
    }
  },
  setLogoUrl: (logoUrl) => {
    return {
      type: PROJECT_SET_LOGO_URL,
      logoUrl
    }
  },
  setProject: (project) => {
    return {
      type: PROJECT_SET,
      project
    }
  },
  toggleColorPicker: (value, mode) => {
    return {
      type: PROJECT_SET_SHOW_COLOR_PICKER,
      value,
      mode
    }
  },
  changeColor: (rgb) => {
    return {
      type: PROJECT_SET_SELECTED_COLOR,
      rgb
    }
  },
  getBranches: (owner, name) => ({
    type: PROJECT_GET_BRANCHES,
    owner,
    name
  }),
  setBranches: (branches) => ({
    type: PROJECT_SET_BRANCHES,
    branches
  }),
  setWorkingBranch: (branch) => ({
    type: PROJECT_SET_WORKING_BRANCH,
    branch
  }),
  togglePill: (index) => {
    return {
      type: PROJECT_TOGGLE_PILL,
      index
    }
  },
  toggleAssetTags: () => {
    return {
      type: PROJECT_TOGGLE_ASSET_TAGS
    }
  },
  showAddAssetTag: () => {
    return {
      type: PROJECT_SHOW_ADD_ASSET_TAG
    }
  },
  editAssetTagText: (assetTag, text) => {
    return {
      type: PROJECT_EDIT_ASSET_TAG_TEXT,
      assetTag,
      text
    }
  },
  setAssetTagText: (text) => {
    return {
      type: PROJECT_SET_ASSET_TAG_TEXT,
      text
    }
  },
  setAssetTagEditMode: (assetTag) => {
    return {
      type: PROJECT_SET_ASSET_TAG_EDIT_MODE,
      assetTag
    }
  },
  editAssetTag: (assetTag, index) => {
    return {
      type: PROJECT_EDIT_ASSET_TAG,
      assetTag,
      index
    }
  },
  setAssetTag: (assetTag) => {
    return {
      type: PROJECT_SET_ASSET_TAG,
      assetTag
    }
  },
  setAssetTagDeleteMode: (assetTag) => {
    return {
      type: PROJECT_SET_ASSET_TAG_DELETE_MODE,
      assetTag
    }
  },
  removeAssetTag: (assetTag) => {
    return {
      type: PROJECT_REMOVE_ASSET_TAG,
      assetTag
    }
  },
  setGitHubReleaseCode: (code) => {
    return {
      type: PROJECT_SET_GITHUB_RELEASE_CODE,
      code
    }
  },
  setSavingState: (state) => {
    return {
      type: PROJECT_SET_SAVING_STATE,
      state
    }
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PROJECT_TOGGLE_CONTEXT_MODAL]: (state, action) =>
    state.set('contextModalOpen', !state.get('contextModalOpen')),
  [PROJECT_TOGGLE_PILL]: (state, action) =>
    state.set('activeTab', action.index),
  [PROJECT_SET_NAME]: (state, action) => state.set('name', action.name),
  [PROJECT_SET_DESCRIPTION]: (state, action) =>
    state.set('description', action.description),
  [PROJECT_SET_LOGO_URL]: (state, action) =>
    state.set('logoUrl', action.logoUrl),
  [PROJECT_SET_REPO_URL]: (state, action) =>
    state.set('repoUrl', action.repoUrl),
  [PROJECT_SELECT_VERSION]: (state, action) =>
    state.set('selectedVersion', action.version),
  [PROJECT_SET_VERSIONS]: (state, action) =>
    state.set('versions', fromJS(action.versions)),
  [PROJECT_SHOW_ADD_TAG]: (state, action) =>
    state.set('displayAddNewTag', !state.get('displayAddNewTag')),
  [PROJECT_PREVIEW_HTML_TEMPLATE_SET_PREVIEW]: (state, action) =>
    state.set('templateHTMLPreview', action.content),

  [PROJECT_SET_RELEASE_PREVIEW_STATE]: (state, action) =>
    state.set('releasePreviewState', action.state),
  [PROJECT_SET_RELEASE_PREVIEW_TAB]: (state, action) =>
    state.set('releasePreviewTab', action.tab),

  [PROJECT_SET_HTML_TEMPLATE]: (state, action) => {
    return state.set('local_htmlTemplate', action.template)
  },
  [PROJECT_SELECT_SCOPE]: (state, action) => {
    return state.set('releasePreviewScope', action.scope)
  },
  [PROJECT_SET_RELEASE_PREVIEW]: (state, action) => {
    return state
      .set('releaseTextPreview', action.text)
      .set('releaseHTMLPreview', action.html)
      .set('releaseError', action.err)
  },
  [PROJECT_SHOW_ADD_LOCALE]: (state, action) =>
    state.set('displayAddNewLocale', !state.get('displayAddNewLocale')),
  [PROJECT_SHOW_ADD_ASSET_TAG]: (state, action) =>
    state.set('displayAddNewAssetTag', !state.get('displayAddNewAssetTag')),
  [PROJECT_SET_TAGS_TEXT]: (state, action) => {
    const tags = state.get('tagList').toJS()

    const exists = tags.filter((item) => {
      return item.text === action.text
    })

    if (!exists || exists.length === 0) {
      state = state.set('displayDuplicateError', false)
    } else {
      state = state.set('displayDuplicateError', true)
    }

    if (!state.get('didSelectHash')) {
      return state
        .set('tagText', action.text)
        .set('selectedColor', getColorHash(action.text))
    } else {
      return state.set('tagText', action.text)
    }
  },
  [PROJECT_PREVIEW_SET_CONTEXT]: (state, action) => {
    let currentState = state
      .set('previewContext', fromJS(action.context))
      .set('isDemoContext', action.isDemo)
    if (action.context.versions.length > 0) {
      currentState = currentState.set(
        'githubContext',
        fromJS({
          project: action.context.project,
          version: action.context.versions[0]
        })
      )
    }
    return currentState
  },
  [PROJECT_SET_TAGS_PRIORITY]: (state, action) => {
    return state.set('tagPriority', action.priority)
  },
  [PROJECT_SET_TAGS]: (state, action) => {
    const tags = state.get('tagList').toJS()
    const tag = action.tag
    tag.editMode = false
    tag.deleteMode = false
    tag.text = tag.text.trim()

    const exists = tags.filter((item) => {
      return item.text === tag.text
    })

    if (!exists || exists.length === 0) {
      tags.push(tag)
      return state.set('tagText', '').set('tagList', fromJS(tags))
    } else {
      return state
    }
  },
  [PROJECT_SET_TAG_EDIT_MODE]: (state, action) => {
    const tags = state.get('tagList').toJS()
    var editingTag = {}
    const t = tags.map((tag) => {
      if (tag.text === action.tag.text) {
        tag.editMode = !tag.editMode
        editingTag = tag
      } else {
        tag.editMode = false
      }

      return tag
    })

    return state.set('tagList', fromJS(t)).set('editingTag', fromJS(editingTag))
  },
  [PROJECT_SET_TAG_DELETE_MODE]: (state, action) => {
    const tags = state.get('tagList').toJS()
    const t = tags.map((tag) => {
      if (tag.text === action.tag.text) {
        tag.deleteMode = !tag.deleteMode
      } else {
        tag.deleteMode = false
      }

      return tag
    })

    return state.set('tagList', fromJS(t))
  },
  [PROJECT_EDIT_TAG]: (state, action) => {
    var t = action.tag
    t.editMode = false
    return state
      .setIn(['tagList', action.index], fromJS(t))
      .set('editingTag', {})
  },
  [PROJECT_EDIT_TAG_TEXT]: (state, action) => {
    var tag = state.get('editingTag').toJS()
    tag.text = action.text

    return state.set('editingTag', fromJS(tag))
  },
  [PROJECT_EDIT_TAG_PRIORITY]: (state, action) => {
    const tag = state.get('editingTag').toJS()
    tag.priority = action.priority
    return state.set('editingTag', fromJS(tag))
  },
  [PROJECT_EDIT_TAG_COLOR]: (state, action) => {
    var tag = state.get('editingTag').toJS()
    tag.color = action.color

    return state.set('editingTag', fromJS(tag))
  },
  [PROJECT_REMOVE_TAGS]: (state, action) => {
    const tags = state.get('tagList').toJS()
    const newTags = tags.filter((item, index) => {
      return item.text !== action.tag.text
    })
    return state.set('tagList', fromJS(newTags))
  },
  [PROJECT_SET_LOCALES_TEXT]: (state, action) => {
    const locales = state.get('locales').toJS()

    const exists = locales.filter((item) => {
      return item.text === action.text
    })

    if (!exists || exists.length === 0) {
      state = state.set('displayDuplicateError', false)
    } else {
      state = state.set('displayDuplicateError', true)
    }

    return state.set('localeText', action.text)
  },
  [PROJECT_SET_LOCALE_DELETE_MODE]: (state, action) => {
    const locales = state.get('locales').toJS()
    const l = locales.map((locale) => {
      if (locale.text === action.locale.text) {
        locale.deleteMode = !locale.deleteMode
      } else {
        locale.deleteMode = false
      }

      return locale
    })

    return state.set('locales', fromJS(l))
  },
  [PROJECT_SET_LOCALE_EDIT_MODE]: (state, action) => {
    const locales = state.get('locales').toJS()
    var editingLocale = {}
    const l = locales.map((locale) => {
      if (locale.text === action.locale.text) {
        locale.editMode = !locale.editMode
        editingLocale = locale
      } else {
        locale.editMode = false
      }

      return locale
    })

    return state
      .set('locales', fromJS(l))
      .set('editingLocale', fromJS(editingLocale))
  },
  [PROJECT_SET_LOCALES]: (state, action) => {
    const locales = state.get('locales').toJS()
    const locale = action.locale
    locale.editMode = false
    locale.deleteMode = false
    locale.text = locale.text.trim()

    const exists = locales.filter((item) => {
      return item.text === locale.text
    })

    if (!exists || exists.length === 0) {
      locales.push(locale)
      return state.set('localeText', '').set('locales', fromJS(locales))
    } else {
      return state
    }
  },
  [PROJECT_REMOVE_LOCALES]: (state, action) => {
    const locales = state.get('locales').toJS()
    const newLocales = locales.filter((item, index) => {
      return item.text !== action.locale.text
    })
    return state.set('locales', fromJS(newLocales))
  },
  [PROJECT_EDIT_LOCALE]: (state, action) => {
    var l = action.locale
    l.editMode = false
    return state
      .setIn(['locales', action.index], fromJS(l))
      .set('editingLocale', {})
  },
  [PROJECT_EDIT_LOCALE_TEXT]: (state, action) => {
    var locale = state.get('editingLocale').toJS()
    locale.text = action.text

    return state.set('editingLocale', fromJS(locale))
  },
  [PROJECT_SET]: (state, action) => {
    action.project.locales = action.project.locales.map((locale) => {
      if (locale.text) {
        return locale
      } else {
        return { text: locale }
      }
    })
    return state.mergeDeep(fromJS(action.project))
  },
  [PROJECT_SET_SHOW_COLOR_PICKER]: (state, action) => {
    if (action.mode === 'new') {
      return state.set('displayColorPickerNew', !action.value)
    } else {
      return state.set('displayColorPickerEdit', !action.value)
    }
  },
  [PROJECT_SET_SELECTED_COLOR]: (state, action) => {
    return state.set('selectedColor', action.rgb).set('didSelectHash', true)
  },
  [PROJECT_SET_BRANCHES]: (state, action) =>
    state.set('branches', fromJS(action.branches)),
  [PROJECT_SET_WORKING_BRANCH]: (state, action) =>
    state.set('workingBranch', action.branch),
  [PROJECT_TOGGLE_ASSET_TAGS]: (state, action) => {
    var enabled = state.get('enableAssetTags')
    return state.set('enableAssetTags', fromJS(!enabled))
  },
  [PROJECT_EDIT_ASSET_TAG_TEXT]: (state, action) => {
    var tag = state.get('editingAssetTag').toJS()
    tag.name = action.text.toLowerCase()

    return state.set('editingAssetTag', fromJS(tag))
  },
  [PROJECT_SET_ASSET_TAG_TEXT]: (state, action) => {
    const assetTags = state.get('assetTags').toJS()

    const exists = assetTags.filter((item) => {
      return item.name === action.text.toLowerCase()
    })

    if (!exists || exists.length === 0) {
      state = state.set('displayDuplicateError', false)
    } else {
      state = state.set('displayDuplicateError', true)
    }

    return state.set('assetTagText', action.text.toLowerCase())
  },
  [PROJECT_SET_ASSET_TAG_EDIT_MODE]: (state, action) => {
    const assetTags = state.get('assetTags').toJS()
    var editingAssetTag = {}
    const a = assetTags.map((assetTag) => {
      if (assetTag.name === action.assetTag.name) {
        assetTag.editMode = !assetTag.editMode
        editingAssetTag = assetTag
      } else {
        assetTag.editMode = false
      }

      return assetTag
    })

    return state
      .set('assetTags', fromJS(a))
      .set('editingAssetTag', fromJS(editingAssetTag))
  },
  [PROJECT_EDIT_ASSET_TAG]: (state, action) => {
    var a = action.assetTag
    a.editMode = false
    return state
      .setIn(['assetTags', action.index], fromJS(a))
      .set('editingAssetTag', {})
  },
  [PROJECT_SET_ASSET_TAG]: (state, action) => {
    const assetTags = state.get('assetTags').toJS()
    const assetTag = action.assetTag
    assetTag.editMode = false
    assetTag.deleteMode = false
    assetTag.name = assetTag.name.trim()

    const exists = assetTags.filter((item) => {
      return item.name === assetTag.name
    })

    if (!exists || exists.length === 0) {
      assetTags.push(assetTag)
      return state.set('assetTagText', '').set('assetTags', fromJS(assetTags))
    } else {
      return state
    }
  },
  [PROJECT_SET_ASSET_TAG_DELETE_MODE]: (state, action) => {
    const assetTags = state.get('assetTags').toJS()
    const a = assetTags.map((assetTag) => {
      if (assetTag.name === action.assetTag.name) {
        assetTag.deleteMode = !assetTag.deleteMode
      } else {
        assetTag.deleteMode = false
      }

      return assetTag
    })

    return state.set('assetTags', fromJS(a))
  },
  [PROJECT_REMOVE_ASSET_TAG]: (state, action) => {
    const assetTags = state.get('assetTags').toJS()
    const newAssetTags = assetTags.filter((item, index) => {
      return item.name !== action.assetTag.name
    })
    return state.set('assetTags', fromJS(newAssetTags))
  },
  [PROJECT_SET_SAVING_STATE]: (state, action) =>
    state.set('savingState', action.state),
  [PROJECT_SET_GITHUB_RELEASE_CODE]: (state, action) =>
    state.set('local_githubReleaseCode', action.code)
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeTab: 'about',
  contextModalOpen: false,
  name: '',
  tagList: [],
  locales: [],
  localeText: '',
  tagText: '',
  githubReleaseCode: '',
  htmlTemplate: '',
  local_htmlTemplate: '',
  releasePreviewScope: 'patch',
  enableAssetTags: false,
  releasePreviewTab: 'github',
  assetTagText: '',
  _id: '',
  repoUrl: '',
  displayColorPickerNew: false,
  versions: [],

  releaseHTMLPreview:
    '<h6 class="m-3 text-align-center">Click Preview Release</h6>',
  displayColorPickerEdit: false,
  displayAddNewTag: false,
  displayAddNewLocale: false,
  displayAddNewAssetTag: false,
  displayDuplicateError: false,
  selectedColor: '',
  didSelectHash: false,
  branches: [],
  workingBranch: '',
  editingTag: {},
  editingLocale: {},
  editingAssetTag: {}
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
// ------------------------------------
// Sagas
// ------------------------------------

function* getBranchesAsync(action) {
  const result = yield call(
    request,
    `/api/${action.owner}/${action.name}/branches`
  )
  if (result.success) {
    yield put(actions.setBranches(result.results))
    const projectState = yield select((state) =>
      state.get('projects/edit').toJS()
    )
    if (!projectState.workingBranch) {
      yield put(actions.setWorkingBranch(result.defaultBranch))
    }
  }
}

function* loadProjectAsync(action) {
  const result = yield call(request, `/api/${action.owner}/${action.name}`)
  if (result.success) {
    result.project.local_githubReleaseCode =
      result.project.githubReleaseCode || ''
    result.project.local_htmlTemplate = result.project.htmlTemplate || ''
    yield put(actions.setProject(result.project))

    const vResult = yield call(
      request,
      `/api/${action.owner}/${action.name}/version`
    )
    if (vResult.success) {
      if (vResult.results && vResult.results.length > 0) {
        yield put(actions.setVersions(vResult.results))
        yield put(actions.selectVersion(vResult.results[0].version))
      } else {
        // Load demo versions
        yield put(actions.setVersions([]))
        yield put(actions.selectVersion(undefined))
      }
    } else {
      if (vResult.message) {
        yield put(errorToast('Oops!', vResult.message))
      }
    }
  }
  yield put(actions.getBranches(action.owner, action.name))
}

function* previewReleaseAsync(action) {
  yield put(actions.setReleasePreviewState('loading'))

  const projectState = yield select((state) =>
    state.get('projects/edit').toJS()
  )
  const version = projectState.selectedVersion
  const code = projectState.local_githubReleaseCode

  const url =
    projectState.versions.length > 0
      ? `/api/projects/${
          projectState._id
        }/notes/format/github?version=${version}&scope=patch`
      : `/api/projects/${PARCEL_DEMO_ID}/notes/format/github?version=${PARCEL_DEMO_VERSION}&scope=patch`

  const result = yield call(request, url, {
    method: 'POST',
    body: JSON.stringify({ template: code })
  })
  if (result.success) {
    yield put(actions.setReleasePreview(result.raw, result.preview))
  } else {
    yield put(actions.setReleasePreview(undefined, undefined, result.message))
  }

  yield put(actions.setReleasePreviewState('ready'))
}

function* previewTemplateAsync(action) {
  yield put(actions.setReleasePreviewState('loading'))

  const projectState = yield select((state) =>
    state.get('projects/edit').toJS()
  )
  const version = projectState.selectedVersion
  const scope = projectState.releasePreviewScope
  const code = projectState.local_htmlTemplate

  const url = `/api/projects/${
    projectState._id
  }/notes/format/html?version=${version}&scope=${scope}&raw=true`
  const result = yield call(request, url, {
    method: 'POST',
    format: 'html',
    body: JSON.stringify({ template: code })
  })
  yield put(actions.setTemplatePreview(result))

  yield put(actions.setReleasePreviewState('ready'))
}

function* loadVersionContextAsync(action) {
  const projectState = yield select((state) =>
    state.get('projects/edit').toJS()
  )

  const url =
    projectState.versions.length > 0
      ? `/api/projects/${projectState._id}/notes?version=${
          projectState.selectedVersion
        }&scope=${projectState.releasePreviewScope}`
      : `/api/projects/${PARCEL_DEMO_ID}/notes?version=${PARCEL_DEMO_VERSION}&scope=${
          projectState.releasePreviewScope
        }`

  const result = yield call(request, url)
  yield put(
    actions.setPreviewNotesContext(result, !projectState.selectedVersion)
  )
  yield put(actions.setHTMLTemplate(projectState.local_htmlTemplate))
}

function* saveProjectAsync(action) {
  yield put(actions.setSavingState('saving'))
  const projectState = yield select((state) =>
    state.get('projects/edit').toJS()
  )
  const project = {
    name: projectState.name.trim(),
    tagList: projectState.tagList,
    githubReleaseCode: action.saveCode
      ? projectState.local_githubReleaseCode
      : projectState.githubReleaseCode,
    htmlTemplate: action.saveCode
      ? projectState.local_htmlTemplate
      : projectState.htmlTemplate,
    enableAssetTags: projectState.enableAssetTags,
    assetTags: projectState.assetTags,
    locales: projectState.locales.map((l) => {
      return l.text
    }),
    repoUrl: projectState.repoUrl.trim(),
    workingBranch: projectState.workingBranch
  }
  const url = projectState._id
    ? `/api/${projectState.owner.login}/${projectState.name}`
    : `/api/projects`
  const method = projectState._id ? 'PUT' : 'POST'

  const result = yield call(request, url, {
    method: method,
    body: JSON.stringify(project)
  })

  if (result.success === true) {
    if (action.saveCode) {
      yield put(
        successToast(
          `${projectState.owner.login}/${projectState.name}`,
          'Template updated!'
        )
      )
    }
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }

  yield put(actions.setSavingState('saved'))
}

function* watchGetBranches() {
  yield takeLatest(PROJECT_GET_BRANCHES, getBranchesAsync)
}

function* watchPreviewTemplate() {
  yield takeLatest(PROJECT_PREVIEW_HTML_TEMPLATE, previewTemplateAsync)
}

function* watchSaveProject() {
  yield takeLatest(
    [
      PROJECT_EDIT_TAG,
      PROJECT_SET_TAGS,
      PROJECT_REMOVE_TAGS,
      PROJECT_EDIT_LOCALE,
      PROJECT_SET_LOCALES,
      PROJECT_REMOVE_LOCALES,
      PROJECT_SET_WORKING_BRANCH,
      PROJECT_TOGGLE_ASSET_TAGS,
      PROJECT_SAVE,
      PROJECT_EDIT_ASSET_TAG,
      PROJECT_REMOVE_ASSET_TAG,
      PROJECT_SET_ASSET_TAG
    ],
    saveProjectAsync
  )
}

function* updatePreview(action) {
  yield delay(200)
  const projectState = yield select((state) =>
    state.get('projects/edit').toJS()
  )
  const context = projectState.previewContext
  try {
    const c = handlebars.compile(projectState.local_htmlTemplate)
    const markup = c(context)
    yield put(actions.setTemplatePreview(markup.toString()))
  } catch (err) {}
}

function* watchPreviewRelease() {
  yield takeLatest(PROJECT_PREVIEW_RELEASE, previewReleaseAsync)
}
function* watchLoadProject() {
  yield takeLatest(PROJECT_LOAD, loadProjectAsync)
}
function* watchSelectVersion() {
  yield takeLatest(
    [PROJECT_SELECT_VERSION, PROJECT_SELECT_SCOPE],
    loadVersionContextAsync
  )
}
function* watchPreviewCodeChange() {
  yield takeLatest([PROJECT_SET_HTML_TEMPLATE], updatePreview)
}

export function* rootSaga() {
  yield fork(watchPreviewCodeChange)
  yield fork(watchSaveProject)
  yield fork(watchSelectVersion)
  yield fork(watchLoadProject)
  yield fork(watchGetBranches)
  yield fork(watchPreviewRelease)
  yield fork(watchPreviewTemplate)
}
