// ------------------------------------
// Imports
// ------------------------------------
import { fork, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import fs from 'fs'

const doc = fs.readFileSync(
  './src/app/static/documentation/documentation.md',
  'utf8'
)
const qsDoc = fs.readFileSync(
  './src/app/static/documentation/quickStart.md',
  'utf8'
)

// ------------------------------------
// Constants
// ------------------------------------

const LOAD_DOCUMENTATION = 'DOCUMENTATION::LOAD'
const DOCUMENTATION_SELECTED_TAB = 'DOCUMENTATION:TAB_SELECTED'

// ------------------------------------
// Actions
// ------------------------------------

export const actions = {
  loadDocumentation: () => {
    return {
      type: LOAD_DOCUMENTATION
    }
  },

  selectTab: (link) => {
    return {
      type: DOCUMENTATION_SELECTED_TAB,
      link
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_DOCUMENTATION]: (state, action) => {
    const tab = state.get('selectedTab')

    return state.set('markdown', tab === 0 ? doc : qsDoc)
  },
  [DOCUMENTATION_SELECTED_TAB]: (state, action) => {
    var linkIndex = action.link === 'home' ? 0 : 1
    return state
      .set('selectedTab', linkIndex)
      .set('markdown', linkIndex === 0 ? doc : qsDoc)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  error: '',
  markdown: '',
  selectedTab: 0
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* loadDocumentationSaga(action) {}

function* watchLoadDocumentation() {
  const patterns = [DOCUMENTATION_SELECTED_TAB, LOAD_DOCUMENTATION]
  yield takeLatest(patterns, loadDocumentationSaga)
}

export function* rootSaga() {
  yield fork(watchLoadDocumentation)
}

// ------------------------------------
// Selectors
// ------------------------------------
export const selectModuleState = () => (state) => state.get('documentation')

export const selectError = () =>
  createSelector(
    selectModuleState(),
    (mState) => mState.get('error')
  )
