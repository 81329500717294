import { connect } from 'react-redux'
import { actions } from '../modules'
import { actions as assignAssetsModalActions } from '~/containers/AssignAssetsModal/reducer'
import Versions from '../components/Versions'

const mapDispatchToProps = {
  ...actions,
  showAssignAssetsModal: assignAssetsModalActions.showModal
}

const mapStateToProps = (state, ownProps) => {
  const versionState = state.get('projects/versions').toJS()
  return {
    ...versionState,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Versions)
