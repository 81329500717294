// ------------------------------------
// Imports
// ------------------------------------
import { put, fork, call, takeLatest } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import request from '../../utils/request'
import action from '../../utils/actions'

// ------------------------------------
// Constants
// ------------------------------------

const GET_ASSETS = 'ASSIGN ASSETS :: GET ASSETS'
const SET_ASSETS = 'ASSIGN ASSETS :: SET ASSETS'
const ASSIGN_ASSETS = 'ASSIGN ASSETS :: ASSIGN ASSETS TO VERSION'
export const ASSIGN_ASSETS_SUCCESS =
  'ASSIGN ASSETS :: ASSIGN ASSETS TO VERSION SUCCESS'
const TOGGLE_VISIBILITY = 'ASSIGN ASSETS :: TOGGLE VISIBILITY'
const SET_SELECTED_ASSET = 'ASSIGN ASSETS :: SET SELECTED ASSET'
const SET_ERROR = 'ASSIGN ASSETS :: SET ERROR'

const REMOVE_ASSET_FROM_VERSION = 'ASSIGN ASSETS :: REMOVE ASSET FROM VERSION'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  showModal: (projectOwner, projectName, version) =>
    action(TOGGLE_VISIBILITY, { projectOwner, projectName, version }),
  getAssets: (projectOwner, projectName, version) =>
    action(GET_ASSETS, { projectOwner, projectName, version }),
  setAssets: (assets, enableAssetTags) =>
    action(SET_ASSETS, { assets, enableAssetTags }),
  setSelectedAsset: (asset) => action(SET_SELECTED_ASSET, { asset }),
  assignAssets: (asset, owner, name, version) =>
    action(ASSIGN_ASSETS, { asset, owner, name, version }),
  assignAssetsSuccess: (owner, name) =>
    action(ASSIGN_ASSETS_SUCCESS, { owner, name }),
  toggleVisibility: () => action(TOGGLE_VISIBILITY),
  setError: (error) => action(SET_ERROR, { error }),
  removeAssetFromVersion: (asset, owner, name, version) =>
    action(REMOVE_ASSET_FROM_VERSION, { asset, owner, name, version })
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_VISIBILITY]: (state, action) => {
    return state
      .set('isOpen', !state.get('isOpen'))
      .set('projectOwner', action.projectOwner)
      .set('projectName', action.projectName)
      .set('version', action.version)
      .set('selectedAsset', undefined)
  },
  [GET_ASSETS]: (state, action) => {
    return state
      .set('loading', true)
      .set('projectOwner', action.projectOwner)
      .set('projectName', action.projectName)
      .set('version', action.version)
  },
  [SET_ASSETS]: (state, action) => {
    return state
      .set('loading', false)
      .set('assets', fromJS(action.assets))
      .set('enableAssetTags', action.enableAssetTags)
  },
  [SET_SELECTED_ASSET]: (state, action) => {
    return state.set('selectedAsset', fromJS(action.asset))
  },
  [SET_ERROR]: (state, action) => {
    return state.set('error', action.error)
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  assets: [],
  isOpen: false,
  projectOwner: '',
  projectName: '',
  version: {},
  loading: false,
  selectedAsset: undefined,
  enableAssetTags: false,
  error: ''
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
// ------------------------------------
// Sagas
// ------------------------------------

function* getAssetsAsync(action) {
  try {
    if (!action.projectOwner || !action.projectName || !action.version) {
      yield put(actions.setAssets([], false))
      return
    }

    const result = yield call(
      request,
      `/api/projects/${action.projectOwner}/${action.projectName}/assets/${
        action.version.baseVersion
      }`
    )

    if (result.success) {
      yield put(actions.setAssets(result.assets, result.enableAssetTags))
    } else {
      yield put(actions.setAssets([], false))
    }
  } catch (e) {
    console.error(e)
    yield put(actions.setAssets([], false))
  }
}

function* assignAssetsAsync(action) {
  try {
    if (!action.asset) {
      return
    }

    const body = {
      method: 'POST',
      body: JSON.stringify({
        commitHash: action.asset.commitHash
      })
    }

    const result = yield call(
      request,
      `/api/projects/${action.version.project}/assets/${
        action.version.version
      }`,
      body
    )

    if (result.success === true) {
      yield put(actions.setError(''))
      yield put(actions.toggleVisibility())
      yield put(actions.assignAssetsSuccess(action.owner, action.name))
    } else {
      yield put(actions.setError(result.message))
    }
  } catch (e) {
    yield put(actions.setError(e.message))
  }
}

function* removeAssetAsync(action) {
  try {
    if (!action.asset) {
      return
    }
    const body = {
      method: 'DELETE',
      body: JSON.stringify({
        commitHash: action.asset.commitHash
      })
    }

    const result = yield call(
      request,
      `/api/projects/${action.version.project}/assets/${
        action.version.version
      }`,
      body
    )

    if (result.success === true) {
      yield put(actions.setError(''))
      yield put(actions.assignAssetsSuccess(action.owner, action.name))
    } else {
      yield put(actions.setError(result.message))
    }
  } catch (e) {
    yield put(actions.setError(e.message))
  }
}
function* watchRemoveAssets() {
  yield takeLatest(REMOVE_ASSET_FROM_VERSION, removeAssetAsync)
}
function* watchGetAssets() {
  yield takeLatest(GET_ASSETS, getAssetsAsync)
}

function* watchAssignAssets() {
  yield takeLatest(ASSIGN_ASSETS, assignAssetsAsync)
}

export function* rootSaga() {
  yield fork(watchGetAssets)
  yield fork(watchAssignAssets)
  yield fork(watchRemoveAssets)
}
