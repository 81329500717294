// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/CoreLayout'
import CompactLayout from '../layouts/CompactLayout'
import WhatsNewRoute from './WhatsNew'
import Home from './Home'
import LandingPage from './LandingPage'
import EditProjectRoute from './Projects/Edit'
import ProjectVersionsRoute from './Projects/Versions'
import VersionAssetsRoute from './Projects/Assets'
import EditVersionRoute from './Projects/EditVersion'
import DocumentationRoute from './Documentation/Home'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = (store) => [
  {
    component: CompactLayout,
    childRoutes: [new DocumentationRoute(store, '/docs(/:tab)')]
  },
  {
    path: '/',
    component: CoreLayout,
    indexRoute: new Home(store, '/'),
    childRoutes: [
      new ProjectVersionsRoute(store, '/(:owner)/(:name)(/versions)'),
      new VersionAssetsRoute(store, '/(:owner)/(:name)(/assets)'),
      new EditProjectRoute(store, '/projects/create'),
      new EditProjectRoute(store, '/(:owner)/(:name)/settings'),
      new EditProjectRoute(store, '/(:owner)/(:name)/settings/:tab')
    ]
  },
  {
    component: CoreLayout,
    childRoutes: [new LandingPage(store, '/welcome')]
  },
  {
    component: CompactLayout,
    childRoutes: [
      new WhatsNewRoute(store, '/releaseNotes'),
      new EditVersionRoute(store, '/(:owner)/(:name)/versions/create'),
      new EditVersionRoute(
        store,
        '/(:owner)/(:name)/versions/v/(:parentVersion)/pre'
      ),
      new EditVersionRoute(store, '/(:owner)/(:name)/versions/(:versionId)'),
      new EditVersionRoute(store, '/(:owner)/(:name)/v/(:version)')
    ]
  }
]

export default createRoutes
