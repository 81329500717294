import { connect } from 'react-redux'
import { actions } from './reducer'
import AssignAssetsModal from './AssignAssetsModal'

const mapDispatchToProps = {
  ...actions
}

const mapStateToProps = (state) => {
  const assignAssetsState = state.get('assignAssets').toJS()
  return assignAssetsState
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignAssetsModal)
