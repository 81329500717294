import PropTypes from 'prop-types'
import Icon from '~/components/Icon'
import ReactMarkdown from 'react-markdown'
import Octicon from 'react-octicon'
import FileUploader from '~/components/FileUploader'
// import ColorHash from 'color-hash'
// const colorHash = new ColorHash()
import { getForegroundForColor } from '../../../../../shared/colorHelper'
import React from 'react'
import Select from 'react-select'

const style = require('./VersionNote.scss')

class SelectedValue extends React.Component {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    value: PropTypes.object,
    disabled: PropTypes.bool.isRequired
  }

  render() {
    let { disabled, value, onRemove } = this.props
    return (
      <div
        className="badge value-option"
        style={{
          backgroundColor: this.props.value.color,
          color: getForegroundForColor(this.props.value.color),
          marginLeft: '5px'
        }}>
        <span
          className="Select-value-icon dim-button"
          aria-hidden="true"
          onMouseDown={(e) => {
            if (!disabled) {
              onRemove(value)
            }
            // for sanity's sake
            e.stopPropagation()
          }}
          onTouchEnd={(e) => {
            onRemove(e)
            e.stopPropagation()
          }}>
          <Icon className="fal fa-times" />
        </span>
        <span className="Select-value-label" role="option">
          {this.props.value.label}
        </span>
      </div>
    )
  }
}

export default class VersionNote extends React.Component {
  getTags(tags) {
    if (!tags) {
      return
    }

    return tags.map((tag) => {
      if (tag.label) {
        return {
          value: tag.label,
          label: tag.label,
          color: tag.color,
          priority: tag.priority
        }
      } else {
        return {
          value: tag.text,
          label: tag.text,
          color: tag.color,
          priority: tag.priority
        }
      }
    })
  }

  renderOption = (option) => {
    return (
      <div
        className="badge value-option"
        style={{
          backgroundColor: option.color,
          color: getForegroundForColor(option.color)
        }}>
        {option.label}
      </div>
    )
  }

  render() {
    return (
      <div
        className="release-note container"
        style={{ backgroundColor: 'transparent' }}>
        <div className="row ">
          <div
            className="col-sm-12 col-md-7 col-lg-8 px-0"
            style={{ minHeight: '100%', alignSelf: 'start' }}>
            <FileUploader
              className="markdown-container"
              onProgress={(e) => {
                if (e.type === 'complete') {
                  this.props.setNoteAttachment(
                    this.props.index,
                    encodeURI(e.url)
                  )
                }
              }}
              uploadUrl="/api/attach"
              style={{ position: 'relative' }}>
              <ReactMarkdown
                className={style.markdownRender}
                source={this.props.item.description || '*Add some text here*'}
                softBreak="br"
              />
              <textarea
                className={style.markdownEditor}
                readOnly={this.props.item.inherited}
                style={{
                  width: '100%',
                  height: '100%',
                  borderColor: 'transparent',
                  backgroundColor:
                    this.props.index % 2 === 0 ? '#f9f5f7' : 'white',
                  padding: '0.5rem',
                  overflow: 'hidden',
                  minHeight: '40px',
                  resize: 'none',
                  outline: 'none',
                  position: 'absolute',
                  bottom: 0,
                  top: 0,
                  right: 0
                }}
                value={this.props.item.description}
                onChange={(e) => {
                  this.props.setNoteText(this.props.index, e.target.value)
                }}
              />
            </FileUploader>
            {this.props.item.attachment && (
              <small className="text-muted m-1 d-block note-footer">
                <Octicon
                  title="Remove attachment"
                  name="trashcan"
                  className="text-muted hover-danger"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.setNoteAttachment(this.props.index, undefined)
                  }}
                />
                <Octicon name="file-media" />{' '}
                <a href={this.props.item.attachment} target="_blank">
                  {this.props.item.attachment}
                </a>
              </small>
            )}
          </div>

          <div
            className="col-sm-11 col-md-3 px-o"
            style={{
              marginBottom: '0px',
              width: '250px',
              alignSelf: 'center'
            }}>
            <Select
              tabSelectsValue={false}
              searchable
              value={this.getTags(this.props.item.tags)}
              multi
              clearable={false}
              onChange={(e) => {
                this.props.setNoteTags(e, this.props.index)
              }}
              options={this.getTags(this.props.tags)}
              optionRenderer={this.renderOption}
              valueComponent={SelectedValue}
              placeholder="Select a tag..."
            />
          </div>

          <div className="col-sm-1 " style={{ alignSelf: 'center' }}>
            <div className="float-right d-flex">
              {this.props.item.pullRequest && (
                <a
                  role="button"
                  href={this.props.item.pullRequest.html_url}
                  target="_blank"
                  className="btn btn-link btn-sm dim-button text-muted hover-success">
                  <Octicon name="git-pull-request" />
                </a>
              )}
              <button
                className="btn btn-sm btn-outline-danger dim-button"
                style={{ alignSelf: 'end' }}
                onClick={() => {
                  this.props.removeNote(this.props.index)
                }}>
                <Icon className="fal fa-fw fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
VersionNote.propTypes = {
  item: PropTypes.object.isRequired,
  setNoteText: PropTypes.func.isRequired,
  setNoteAttachment: PropTypes.func.isRequired,
  removeNote: PropTypes.func.isRequired,
  index: PropTypes.any.isRequired,
  setNoteTags: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired
}
