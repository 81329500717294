import Notifications from 'react-notification-system-redux'

export function errorToast (title, message, options = {}) {
  return createNotification('error', title, message, options)
}

export function infoToast (title, message, options = {}) {
  return createNotification('info', title, message, options)
}

export function successToast (title, message, options = {}) {
  return createNotification('success', title, message, options)
}

export function warningToast (title, message, options = {}) {
  return createNotification('warning', title, message, options)
}

function createNotification (level, title, message, options = {}) {
  return Notifications.show({
    title: title,
    message: message,
    ...options
  }, level)
}
