// ------------------------------------
// Imports
// ------------------------------------

import { put, fork, call, takeLatest } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import request from '~/utils/request'
import { ASSIGN_ASSETS_SUCCESS } from '~containers/AssignAssetsModal/reducer'
import { errorToast } from '~/utils/notifications'

// ------------------------------------
// Constants
// ------------------------------------
const VERSIONS_LOAD = 'VERSIONS::LOAD'
const VERSIONS_SET_ITEMS = 'VERSIONS::SET_ITEMS'
const VERSIONS_SET_PROJECT = 'VERSIONS::SET_PROJECT'

const VERSIONS_SET_FILTER = 'VERSIONS::SET_FILTER'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  loadVersions: (owner, name) => {
    return {
      type: VERSIONS_LOAD,
      owner,
      name
    }
  },
  setVersions: (versions) => {
    return {
      type: VERSIONS_SET_ITEMS,
      versions
    }
  },
  setProject: (project) => {
    return {
      type: VERSIONS_SET_PROJECT,
      project
    }
  },
  setFilter: (filter) => {
    return {
      type: VERSIONS_SET_FILTER,
      filter
    }
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [VERSIONS_LOAD]: (state, action) => state.set('loading', true),
  [VERSIONS_SET_ITEMS]: (state, action) =>
    state.set('loading', false).set('versions', fromJS(action.versions)),
  [VERSIONS_SET_PROJECT]: (state, action) =>
    state.set('project', fromJS(action.project)),
  [VERSIONS_SET_FILTER]: (state, action) =>
    state.set('versionFilter', action.filter)
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  versionFilter: '',
  versions: [],
  project: {}
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
// ------------------------------------
// Sagas
// ------------------------------------

function* loadVersionsAsync(action) {
  const result = yield call(
    request,
    `/api/${action.owner}/${action.name}/version`
  )
  if (result.success) {
    yield put(actions.setProject(result.project))
    yield put(actions.setVersions(result.results))
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function* watchLoadVersions() {
  yield takeLatest([VERSIONS_LOAD, ASSIGN_ASSETS_SUCCESS], loadVersionsAsync)
}

export function* rootSaga() {
  yield fork(watchLoadVersions)
}
