import React from 'react'
import Header from '~/components/Header'
import Icon from '~/components/Icon'
import styles from './Home.scss'
import { Link } from 'react-router'
import { Markdown } from 'react-showdown'

export class Home extends React.Component {
  componentDidMount() {
    const params = this.props.params
    console.log('TCL: Home -> componentDidMount -> params', params)
    if (this.props.params) {
      this.props.selectTab(this.props.params.tab)
    } else {
      this.props.selectTab('home')
    }
  }

  render() {
    const activeLinkStyle = {
      color: '#0099e5'
    }

    const linkStyle = {
      color: 'black'
    }

    return (
      <div className="container-fluid px-0">
        <Header hideQuote sticky className="row" />

        <div className="row mr-0">
          <nav
            className="ml-1 col-md-4 col-sm-12 navbar-inverse bg-inverse navbar-toggleable-md"
            id="navbarSupportedContent"
            style={{ backgroundColor: '#f5f7f9' }}>
            <div
              className="pt-3 float-md-right float-sm-left"
              style={{ backgroundColor: '#f5f7f9', fontSize: '20px' }}>
              <div className={`mb-2 ${styles.tocLinkItem}`}>
                <Link
                  style={
                    this.props.selectedTab === 0 ? activeLinkStyle : linkStyle
                  }
                  to="/docs/home"
                  onClick={() => {
                    this.props.selectTab('home')
                  }}>
                  <Icon className="fal fa-fw fa-home" /> Home
                </Link>
              </div>
              <div className={`mb-2 ${styles.tocLinkItem}`}>
                <Link
                  to="/docs/quickstart"
                  style={
                    this.props.selectedTab === 1 ? activeLinkStyle : linkStyle
                  }
                  onClick={() => {
                    this.props.selectTab('quickstart')
                  }}>
                  <Icon className="fal fa-fw fa-bolt" /> Quick Start
                </Link>
              </div>
              <div className={`mb-2 ${styles.tocLinkItem}`}>
                <a href="https://parcelapp.docs.apiary.io" target="_blank">
                  <Icon className="fal fa-fw fa-book" /> API Reference
                </a>
              </div>
            </div>
          </nav>
          <div
            className="col-sm-9 col-md-7"
            style={{
              marginLeft: '15px',
              marginRight: '30px',
              minHeight: '1020px'
            }}>
            <Markdown markdown={this.props.markdown} />
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {}

export default Home
