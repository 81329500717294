import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import Icon from '~/components/Icon'
import VersionNumberBadge from '~/components/VersionNumberBadge'

export default class AssetItem extends React.PureComponent {
  render() {
    if (
      !Array.isArray(this.props.asset.assets) ||
      this.props.asset.assets.length === 0
    ) {
      return null
    }

    const assetInfo = this.props.asset.assets[0]
    const binaryName = assetInfo.binaryUrl.substring(
      assetInfo.binaryUrl.lastIndexOf('/') + 1
    )

    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <Link
              to={assetInfo.binaryUrl}
              className="text-info"
              target="_blank"
              onClick={(e) => {
                e.stopPropagation()
              }}>
              {assetInfo.name || binaryName}
            </Link>
          </div>
          <div>{moment(assetInfo.createdDate).format('llll')}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Icon className="fal fa-code-branch fa-fw mr-2" />
            {assetInfo.versionString}
            {this.renderAssociatedVersions(assetInfo)}
          </div>
          <div>
            <Icon className="fal fa-code-commit fa-fw mr-2" />
            <Link
              to={`https://github.com/${this.props.projectOwner}/${
                this.props.projectName
              }/commit/${this.props.asset.commitHash}`}
              className="text-info"
              target="_blank"
              onClick={(e) => {
                e.stopPropagation()
              }}>
              {this.props.asset.commitHash.substr(0, 7)}
            </Link>
          </div>
        </div>
      </div>
    )
  }

  renderAssociatedVersions(assetInfo) {
    if (!assetInfo.versions || assetInfo.versions.length === 0) {
      return null
    }

    const versions = assetInfo.versions.map((v) => {
      return (
        <Link
          key={v._id}
          className="mr-2"
          to={`${this.props.projectOwner}/${this.props.projectName}/v/${
            v.version
          }`}>
          <VersionNumberBadge version={v} />
        </Link>
      )
    })

    return (
      <span className="ml-3">
        <Icon className="fal fa-link fa-fw mr-2" />
        {versions}
      </span>
    )
  }
}

AssetItem.propTypes = {
  asset: PropTypes.object.isRequired,
  projectOwner: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired
}
