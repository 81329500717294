import { combineReducers } from 'redux-immutable'
import { reducer as notifications } from 'react-notification-system-redux'
import { routerReducer } from 'react-router-redux'
import assignAssetsReducer from '../containers/AssignAssetsModal/reducer'

import globalReducer from '../containers/App/reducer'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    global: globalReducer,
    assignAssets: assignAssetsReducer,
    routing: routerReducer,
    notifications: notifications,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
