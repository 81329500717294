import { put, fork, select, call, takeLatest } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import request from '~/utils/request'

export const actions = {}

const ACTION_HANDLERS = {}

const initialState = {}

export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

export function* rootSaga() {}
