import fontawesome from '@fortawesome/fontawesome-pro'
import React from 'react'
import Header from '../../../components/Header'
import './releaseNotes.scss'
import 'lightbox2/dist/css/lightbox.min.css'
require('lightbox2')

import fs from 'fs'

const releaseNotes = fs.readFileSync('./src/shared/releaseNotes.txt', 'utf8')

export default class WhatsNewView extends React.Component {
  render() {
    return (
      <div>
        <Header hideQuote sticky className="row" />
        <div className="container pt-5">
          <div className="card">
            <div className="card-header">Release Notes</div>
            <div className="card-body">
              <h5 className="card-title">What's new with Parcel?</h5>
              <p className="card-text">
                Our release notes were generated by Parcel using custom HTML
                Templates
              </p>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
        </div>
      </div>
    )
  }
}
