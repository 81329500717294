import React from 'react'
import Icon from '~/components/Icon'
import Ribbon from '../../../components/Ribbon'
import styles from './LandingPage.scss'
import FrontPageImage from '~/static/images/frontpage.gif'

export default class LandingPage extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <nav className="row navbar sticky-top navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="#">
            <span className="text-info">
              <Icon className="fal fa-fw fa-gift" /> Parcel
            </span>{' '}
            - OPEN BETA
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <a
              href="/auth/github"
              role="button"
              className="ml-auto btn btn-outline-success">
              <Icon className="fab fa-github mr-1" />
              Login with GitHub
            </a>
          </div>
        </nav>

        <div className="row justify-content-center align-items-center my-3">
          <div className="col-sm-12 col-lg-6 order-lg-2 text-center">
            <div className="mx-sm-4 mx-md-0">
              <div className={styles.parcelTitle}>
                <Icon className="fal fa-fw fa-gift" /> Parcel
              </div>
              <div className={styles.parcelSubtitle}>
                Simple Release Management
              </div>
              <p>
                Creating releases and managing release notes for a project is a
                pain. <br className="d-none d-sm-block" />
                Why not let Parcel package everything up for you?
              </p>
              <Ribbon className="bg-success px-3 px-md-4">
                <span className={styles.freeBannerContent}>
                  <Icon className="fal fa-dollar-sign" /> Free during Open BETA
                </span>
              </Ribbon>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-lg-1">
            <div className="text-center">
              <img src={FrontPageImage} className="mw-100" />
            </div>
          </div>
        </div>

        <div
          id="whyparcel"
          className="row"
          style={{ backgroundColor: '#f8f9fa', minHeight: 300 }}>
          <div className="col-12">
            <div className="row">
              <div className="col-auto mx-auto">
                <h2 className="my-4">Why Parcel?</h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-stretch mb-4 mx-4 mx-xl-5">
              <div className="col-12 col-lg align-self-center">
                <p className="text-center mx-3 my-4 mx-md-5 m-lg-3 m-xl-5">
                  Choose from default templates or create your own custom
                  template using our flexible editor for visualizing release
                  notes. Include images or animated gifs to give your notes a
                  little more style
                </p>
              </div>
              <div className="col-auto p-0 border-right d-none d-lg-block" />
              <div className="col-sm-12 border-bottom d-lg-none" />
              <div className="col-12 col-lg align-self-center">
                <p className="text-center mx-3 my-4 mx-md-5 m-lg-3 m-xl-5">
                  Parcel assists in managing your releases from beginning to
                  end. Create a project by authenticating with GitHub. Start
                  creating versions, version notes and even releases in GitHub!
                  Connect Parcel to your CI/CD processes and get the latest
                  release binaries and release notes to serve to your users!
                </p>
              </div>
              <div className="col-auto p-0 border-left d-none d-lg-block" />
              <div className="col-sm-12 border-bottom d-lg-none" />
              <div className="col-12 col-lg align-self-center">
                <p className="text-center mx-3 my-4 mx-md-5 m-lg-3 m-xl-5">
                  Coming Soon: Integrate user feedback within your application.
                  Feedback allows you to interact with users and specifically
                  pinpoint and resolve issues that they are experiencing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
