import ColorHash from 'color-hash'
const colorHash = new ColorHash()

export function getColorHash (text) {
  return colorHash.hex(text)
}

// Foreground color calculation is based on this StackOverflow answer https://stackoverflow.com/a/3943023/805513
// The threshold for dark/light foreground has been modified based on using #24292E instead of #000000
export function getForegroundForColor (hex) {
  const rgb = convertHex(hex) // colorHash.rgb(hex)

  const l = calculateLuminance(rgb)
  return l > 0.478 ? '#24292E' : '#FFFFFF'
}

export function calculateLuminance (rgb) {
  rgb = rgb.map((c) => {
    c = c / 255.0
    c = (c <= 0.03928) ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
    return c
  })

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

export function convertHex (hex) {
  if (!hex) {
    return [255, 255, 255]
  }

  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return [r, g, b]
}

export function getRandomColor () {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

