// ------------------------------------
// Imports
// ------------------------------------
import _ from 'lodash'
import moment from 'moment'
import { put, fork, select, call, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { push } from 'react-router-redux'
import { fromJS } from 'immutable'
import request from '~/utils/request'
import { successToast, errorToast } from '~/utils/notifications'
import Constants from '../../../../constants'
import { ASSIGN_ASSETS_SUCCESS } from '~/containers/AssignAssetsModal/reducer'

// ------------------------------------
// Constants
// ------------------------------------
const EDIT_VERSION_RESET_STATE = 'EDIT_VERSION::RESET_STATE'

const EDIT_VERSION_LOAD_PROJECT = 'EDIT_VERSION::LOAD_PROJECT'
const EDIT_VERSION_SET_PROJECT = 'EDIT_VERSION::SET_PROJECT'

const EDIT_VERSION_SET_BASE_VERSION = 'EDIT_VERSION::SET_BASE_VERSION'
const EDIT_VERSION_SET_NAME = 'EDIT_VERSION::SET_NAME'
const EDIT_VERSION_SET_HEADER = 'EDIT_VERSION::SET_HEADER'
const EDIT_VERSION_SET_LOCALE = 'EDIT_VERSION::SET_LOCALE'
const EDIT_VERSION_ADD_NOTE = 'EDIT_VERSION::ADD_NOTE'
const EDIT_VERSION_REMOVE_NOTE = 'EDIT_VERSION::REMOVE_NOTE'
const EDIT_VERSION_SET_NOTE_TEXT = 'EDIT_VERSION::SET_NOTE_TEXT'
const EDIT_VERSION_SET_NOTE_ATTACHMENT = 'EDIT_VERSION::SET_NOTE_ATTACHMENT'
const EDIT_VERSION_SET_APPROVED = 'EDIT_VERSION::SET_APPROVED'

const EDIT_VERSION_SET_NOTE_TAGS = 'EDIT_VERSION::SET_NOTE_TAGS'
const EDIT_VERSION_SET_LOCALE_NOTES = 'EDIT_VERSION::SET_LOCALE_NOTES'

const EDIT_VERSION_LOAD_VERSION = 'EDIT_VERSION::LOAD_VERSION'
const EDIT_VERSION_SAVE_VERSION = 'EDIT_VERSION::SAVE_VERSION'
const EDIT_VERSION_SAVE_VERSION_COMPLETE = 'EDIT_VERSION::SAVE_VERSION_COMPLETE'
const EDIT_VERSION_SET_VERSION = 'EDIT_VERSION::SET_VERSION'

const EDIT_VERSION_SET_NOTES = 'EDIT_VERSION::SET_NOTES'
const EDIT_VERSION_SET_WORKING_BRANCH = 'EDIT_VERSION::SET WORKING BRANCH'
const EDIT_VERSION_GET_BRANCHES = 'EDIT_VERSION::GET BRANCHES'
const EDIT_VERSION_SET_BRANCHES = 'EDIT_VERSION::SET BRANCHES'

const EDIT_VERSION_GET_PULL_REQUESTS = 'EDIT_VERSION::GET PULL REQUESTS'
const EDIT_VERSION_SET_PULL_REQUESTS = 'EDIT_VERSION::SET PULL REQUESTS'
const EDIT_VERSION_SET_PULL_REQUEST_STATE =
  'EDIT_VERSION::SET PULL REQUESTS STATE FILTER'
const EDIT_VERSION_SET_PULL_REQUEST_SELECTION =
  'EDIT_VERSION::TOGGLE PULL REQUEST SELECTION'
const EDIT_VERSION_TOGGLE_PULL_REQUESTS_MODAL_VISIBLE =
  'EDIT_VERSION::TOGGLE PULL REQUESTS MODAL VISIBLE'
const EDIT_VERSION_ADD_NOTES_FROM_PULL_REQUESTS =
  'EDIT_VERSION::ADD NOTES FROM PULL REQUESTS'

const EDIT_VERSION_CREATE_GITHUB_RELEASE = 'EDIT_VERSION::CREATE GITHUB RELEASE'
const EDIT_VERSION_SET_PRERELEASE = 'EDIT_VERSION::SET PRERELEASE'
const EDIT_VERSION_SET_INCLUDE_PARENT_NOTES =
  'EDIT_VERSION::SET INCLUDE PARENT NOTES'
const EDIT_VERSION_SET_PR_FILTER = 'EDIT_VERSION::SET_PR_FILTER'

const EDIT_VERSION_SET_CHANNELS = 'EDIT_VERSION::SET_CHANNELS'
const EDIT_VERSION_SET_PRERELEASE_CHANNEL =
  'EDIT_VERSION::SET_PRERELEASE_CHANNEL'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  setIncludeParentNotes: (enabled) => ({
    type: EDIT_VERSION_SET_INCLUDE_PARENT_NOTES,
    enabled
  }),
  setPreRelease: (enabled) => ({
    type: EDIT_VERSION_SET_PRERELEASE,
    enabled
  }),
  togglePullRequestModalVisible: () => ({
    type: EDIT_VERSION_TOGGLE_PULL_REQUESTS_MODAL_VISIBLE
  }),
  getPullRequests: () => ({
    type: EDIT_VERSION_GET_PULL_REQUESTS
  }),
  setPullRequests: (pullRequests) => ({
    type: EDIT_VERSION_SET_PULL_REQUESTS,
    pullRequests
  }),
  setPullRequestStateFilter: (state) => ({
    type: EDIT_VERSION_SET_PULL_REQUEST_STATE,
    state
  }),
  setPullRequestSelected: (index, selected) => ({
    type: EDIT_VERSION_SET_PULL_REQUEST_SELECTION,
    index,
    selected
  }),
  addNotesFromPullRequests: () => ({
    type: EDIT_VERSION_ADD_NOTES_FROM_PULL_REQUESTS
  }),
  resetState: () => {
    return {
      type: EDIT_VERSION_RESET_STATE
    }
  },
  saveVersion: () => {
    return {
      type: EDIT_VERSION_SAVE_VERSION
    }
  },
  saveVersionComplete: () => {
    return {
      type: EDIT_VERSION_SAVE_VERSION_COMPLETE
    }
  },
  loadVersion: (owner, name, versionId, versionNumber, forPreRelease) => {
    return {
      type: EDIT_VERSION_LOAD_VERSION,
      owner,
      name,
      versionId,
      versionNumber,
      forPreRelease
    }
  },
  loadProject: (owner, name) => {
    return {
      type: EDIT_VERSION_LOAD_PROJECT,
      owner,
      name
    }
  },
  setApproved: (approved) => {
    return {
      type: EDIT_VERSION_SET_APPROVED,
      approved
    }
  },
  setVersion: (version) => {
    return {
      type: EDIT_VERSION_SET_VERSION,
      version
    }
  },
  setProject: (project) => {
    return {
      type: EDIT_VERSION_SET_PROJECT,
      project
    }
  },
  setBaseVersion: (baseVersion) => {
    return {
      type: EDIT_VERSION_SET_BASE_VERSION,
      baseVersion
    }
  },
  setName: (name) => {
    return {
      type: EDIT_VERSION_SET_NAME,
      name
    }
  },
  setNotes: (notes) => {
    return {
      type: EDIT_VERSION_SET_NOTES,
      notes
    }
  },
  setLocaleNotes: (notes) => {
    return {
      type: EDIT_VERSION_SET_LOCALE_NOTES,
      items: notes
    }
  },
  setLocale: (locale) => {
    return {
      type: EDIT_VERSION_SET_LOCALE,
      locale
    }
  },
  setHeader: (header) => {
    return {
      type: EDIT_VERSION_SET_HEADER,
      header
    }
  },
  addNote: () => {
    return {
      type: EDIT_VERSION_ADD_NOTE
    }
  },
  removeNote: (index) => {
    return {
      type: EDIT_VERSION_REMOVE_NOTE,
      index
    }
  },
  setNoteTags: (tags, index) => {
    return {
      type: EDIT_VERSION_SET_NOTE_TAGS,
      tags,
      index
    }
  },
  setNoteAttachment: (index, attachment) => {
    return {
      type: EDIT_VERSION_SET_NOTE_ATTACHMENT,
      index,
      attachment
    }
  },
  setNoteText: (index, text) => {
    return {
      type: EDIT_VERSION_SET_NOTE_TEXT,
      index,
      text
    }
  },
  getBranches: (owner, repo) => ({
    type: EDIT_VERSION_GET_BRANCHES,
    owner,
    repo
  }),
  setBranches: (branches) => ({
    type: EDIT_VERSION_SET_BRANCHES,
    branches
  }),
  setWorkingBranch: (branch) => ({
    type: EDIT_VERSION_SET_WORKING_BRANCH,
    branch
  }),
  createGitHubRelease: () => ({
    type: EDIT_VERSION_CREATE_GITHUB_RELEASE
  }),
  setPRFilter: (filter) => {
    return {
      type: EDIT_VERSION_SET_PR_FILTER,
      filter
    }
  },
  setChannels: (channels) => {
    return {
      type: EDIT_VERSION_SET_CHANNELS,
      channels
    }
  },
  setPreReleaseChannel: (channel) => {
    return {
      type: EDIT_VERSION_SET_PRERELEASE_CHANNEL,
      channel
    }
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [EDIT_VERSION_LOAD_PROJECT]: (state, action) => state.set('loading', true),
  [EDIT_VERSION_SET_PROJECT]: (state, action) =>
    state
      .set('project', fromJS(action.project))
      .set('loading', false)
      .set('locale', action.project.locales[0]),
  /* PRERELEASE VERSION */
  [EDIT_VERSION_SET_PRERELEASE]: (state, action) => {
    return state
      .set('prerelease', action.enabled)
      .set('prereleaseName', 'pre-' + moment().format('X'))
  },
  [EDIT_VERSION_SET_INCLUDE_PARENT_NOTES]: (state, action) =>
    state.set('includeParentNotes', action.enabled),

  /* WORKING BRANCH */
  [EDIT_VERSION_SET_BRANCHES]: (state, action) =>
    state.set('projectBranches', fromJS(action.branches)),
  [EDIT_VERSION_SET_WORKING_BRANCH]: (state, action) =>
    state.set('workingBranch', action.branch),
  /* END WORKING BRANCH */

  /* PULL REQUESTS */
  [EDIT_VERSION_SET_PR_FILTER]: (state, action) =>
    state.set('prFilter', action.filter),
  [EDIT_VERSION_TOGGLE_PULL_REQUESTS_MODAL_VISIBLE]: (state, action) => {
    return state
      .setIn(
        ['pullRequests', 'visible'],
        !state.getIn(['pullRequests', 'visible'])
      )
      .set('prFilter', '')
  },
  [EDIT_VERSION_SET_PULL_REQUESTS]: (state, action) =>
    state.setIn(['pullRequests', 'items'], fromJS(action.pullRequests)),
  [EDIT_VERSION_SET_PULL_REQUEST_STATE]: (state, action) =>
    state.setIn(['pullRequests', 'state'], action.state),
  [EDIT_VERSION_SET_PULL_REQUEST_SELECTION]: (state, action) => {
    return state.setIn(
      ['pullRequests', 'items', action.index, 'selected'],
      action.selected
    )
  },
  [EDIT_VERSION_ADD_NOTES_FROM_PULL_REQUESTS]: (state, action) => {
    const prs = state.getIn(['pullRequests', 'items']).toJS()
    const selectedPullRequests = prs.filter((pr) => pr.selected)
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        selectedPullRequests.map((spr) => {
          n.items.unshift({
            tags: [],
            description: spr.title,
            pullRequest: spr
          })
        })
      }
    })

    return state
      .set('notes', fromJS(notes))
      .setIn(['pullRequests', 'visible'], false)
      .set('prFilter', '')
  },
  /* END PULL REQUESTS */

  /* CREATE GITHUB RELEASE */
  [EDIT_VERSION_CREATE_GITHUB_RELEASE]: (state, action) =>
    state.set('creatingRelease', true),

  [EDIT_VERSION_SET_BASE_VERSION]: (state, action) =>
    state.set('baseVersion', action.baseVersion),
  [EDIT_VERSION_SET_NAME]: (state, action) => state.set('name', action.name),
  [EDIT_VERSION_RESET_STATE]: (state, action) => fromJS(initialState),
  [EDIT_VERSION_SET_NOTES]: (state, action) =>
    state.set('notes', fromJS(action.notes)),

  [EDIT_VERSION_SET_LOCALE]: (state, action) =>
    state.set('locale', action.locale),
  [EDIT_VERSION_SET_APPROVED]: (state, action) =>
    state.set('approved', action.approved),
  [EDIT_VERSION_SET_HEADER]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.header = action.header
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SET_NOTE_TAGS]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items[action.index].tags = action.tags.map((tag) => {
          return {
            text: tag.value,
            color: tag.color,
            priority: tag.priority
          }
        })
      }
    })
    return state.set('notes', fromJS(notes))
  },

  [EDIT_VERSION_REMOVE_NOTE]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items.splice(action.index, 1)
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SET_LOCALE_NOTES]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items = action.items
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SET_NOTE_ATTACHMENT]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items[action.index].attachment = action.attachment
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SET_NOTE_TEXT]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items[action.index].description = action.text
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SAVE_VERSION]: (state, action) => state.set('saving', true),
  [EDIT_VERSION_SAVE_VERSION_COMPLETE]: (state, action) => {
    return state.set('saving', false)
  },
  [EDIT_VERSION_ADD_NOTE]: (state, action) => {
    const locale = state.get('locale')
    const notes = state.get('notes').toJS()
    notes.forEach((n) => {
      if (n.locale === locale) {
        n.items.unshift({
          tags: [],
          description: ''
        })
      }
    })
    return state.set('notes', fromJS(notes))
  },
  [EDIT_VERSION_SET_VERSION]: (state, action) => {
    const version = action.version

    let parentVersion
    let parentNotes

    if (version.parentVersion) {
      parentVersion = version.parentVersion._id
      parentNotes = version.parentVersion.notes
    }

    return state
      .set('baseVersion', version.baseVersion)
      .set('version', version.version)
      .set('releaseTag', version.releaseTag)
      .set('assets', fromJS(version.assets))
      .set('name', version.name)
      .set('_id', version._id)
      .set('includeParentNotes', version.includeParentNotes)
      .set('parentVersion', parentVersion)
      .set('parentNotes', fromJS(parentNotes))
      .set('notes', fromJS(version.notes))
      .set('approved', version.status === 1)
      .set('channel', version.channel)
      .set('revision', version.revision)
      .set('channelName', version.channelName)
      .set('prerelease', version.prerelease)
      .set('originalChannel', version.channel)
      .set('originalRevision', version.revision)
      .set('canEditChannel', version.canEditChannel)
  },
  [EDIT_VERSION_SET_CHANNELS]: (state, action) => {
    return state.set('channels', fromJS(action.channels))
  },
  [EDIT_VERSION_SET_PRERELEASE_CHANNEL]: (state, action) => {
    const channels = state.get('channels').toJS()
    const originalChannel = state.get('originalChannel')
    const selectedChannel = _.find(channels, (c) => c.value === action.channel)

    return state
      .set('channel', selectedChannel.value)
      .set(
        'revision',
        selectedChannel.value === originalChannel
          ? state.get('originalRevision')
          : selectedChannel.revisions + 1
      )
      .set('channelName', selectedChannel.semverName)
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  project: {},
  assets: [],
  projectBranches: [],
  approved: false,
  baseVersion: '0.0.0',
  channel: Constants.PublicChannel,
  prerelease: false,
  channelName: '',
  revision: 0,
  name: '',
  prFilter: '',
  pullRequests: {
    items: [],
    state: 'closed',
    text: '',
    selected: [],
    visible: false
  },
  notes: [],
  channels: [],
  canEditChannel: false
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
// ------------------------------------
// Sagas
// ------------------------------------

function* getPullRequestsAsync(action) {
  const localState = yield select((state) => {
    return state.get('versions/edit').toJS()
  })
  const url =
    `/api/project/${localState.project.owner.login}/${
      localState.project.name
    }/${localState.workingBranch}/` +
    `pullRequests?state=${localState.pullRequests.state}`
  const result = yield call(request, url)
  if (result.success) {
    // Do something
    yield put(actions.setPullRequests(result.results))
  }
}
function* getBranchesAsync(action) {
  const result = yield call(
    request,
    `/api/${action.owner}/${action.repo}/branches`
  )
  if (result.success) {
    yield put(actions.setBranches(result.results))
  }
}
function* saveVersionAsync(action) {
  const {
    _id,
    approved,
    baseVersion,
    channel,
    channelName,
    includeParentNotes,
    name,
    notes,
    parentVersion,
    prerelease,
    project,
    revision,
    workingBranch
  } = yield select((state) => {
    return state.get('versions/edit').toJS()
  })

  const version = initializeVersion({
    baseVersion,
    channel,
    channelName,
    revision,
    approved,
    project,
    name,
    notes,
    workingBranch,
    prerelease,
    includeParentNotes,
    parentVersion
  })

  const updateVersionResponse = yield makeUpdateVersionRequest(
    _id,
    version,
    project
  )

  yield confirmResultAndSave(updateVersionResponse, project, version)
}

function* loadProjectAsync(action) {
  yield put(actions.setBaseVersion('0.0.0'))
  const result = yield call(request, `/api/${action.owner}/${action.name}`)
  if (result.success) {
    yield put(actions.setProject(result.project))
    const notes = result.project.locales.map((l) => {
      return {
        locale: l,
        header: '',
        items: []
      }
    })
    yield put(actions.setNotes(notes))
    yield put(actions.setLocale(result.project.locales[0]))
    yield put(actions.setWorkingBranch(result.project.workingBranch))
    yield put(actions.getPullRequests())
  }
}
function* loadVersionAsync(action) {
  try {
    const url = action.versionId
      ? `/api/project/${action.owner}/${action.name}/version/${
          action.versionId
        }`
      : `/api/project/${action.owner}/${action.name}/v/${action.versionNumber}`
    const result = yield call(request, url)
    if (result.success) {
      yield put(actions.setProject(result.project))
      yield put(actions.setChannels(result.channels))
      yield delay(100)
      let newVersion = { releaseTag: result.releaseTag, ...result.version }
      result.version.releaseTag = result.releaseTag

      if (action.forPreRelease) {
        const id = newVersion._id
        delete newVersion._id
        newVersion.prerelease = true
        newVersion.parentVersion = {
          _id: id,
          notes: []
        }

        if (result.channels) {
          const revision = result.channels[0].revisions + 1
          newVersion.channel = result.channels[0].value
          newVersion.revision = revision
          newVersion.channelName = result.channels[0].semverName
          newVersion.canEditChannel = true
        }

        newVersion.notes = newVersion.notes.map((n) => {
          let clonedLocaleNote = {}
          Object.assign(clonedLocaleNote, n)
          newVersion.parentVersion.notes.push(clonedLocaleNote)
          n.items = []
          return n
        })
      } else if (newVersion.channel !== Constants.PublicChannel) {
        newVersion.prerelease = true
        const versionChannel = _.find(
          result.channels,
          (c) => c.value === newVersion.channel
        )
        newVersion.channelName = versionChannel.semverName
        newVersion.canEditChannel =
          newVersion.version === result.latestPrerelease &&
          _.size(newVersion.assets) === 0
      }

      newVersion.assets = setAssetTagNames(
        newVersion.assets,
        result.project.assetTags
      )

      yield put(actions.setVersion(newVersion))
      if (result.version.workingBranch) {
        yield put(actions.setWorkingBranch(result.version.workingBranch))
        yield put(actions.getPullRequests())
      }
    } else {
      if (result.message) {
        yield put(errorToast('Oops!', result.message))
      }
    }
  } catch (e) {
    console.error(e)
    yield put(errorToast('Oops!', 'Something unexpected happened'))
  }
}

function* createGitHubReleaseAsync(action) {
  const versionState = yield select((state) => {
    return state.get('versions/edit').toJS()
  })
  const owner = versionState.project.owner.login
  const name = versionState.project.name
  const version = versionState.prerelease
    ? `${versionState.baseVersion}-${versionState.channelName}.${
        versionState.revision
      }`
    : versionState.baseVersion

  const result = yield call(
    request,
    `/api/project/${owner}/${name}/v/${version}/release`,
    { method: 'POST' }
  )
  if (result.success) {
    yield put(actions.loadVersion(owner, name, null, version))
  }
}

function* refreshViewAsync() {
  const versionState = yield select((state) => {
    return state.get('versions/edit').toJS()
  })
  yield put(
    actions.loadVersion(
      versionState.project.owner.login,
      versionState.project.name,
      undefined,
      versionState.version
    )
  )
}

function* watchAssignAssetSuccess() {
  yield takeLatest(ASSIGN_ASSETS_SUCCESS, refreshViewAsync)
}

function* watchGetBranches() {
  yield takeLatest(EDIT_VERSION_GET_BRANCHES, getBranchesAsync)
}
function* watchSaveVersion() {
  yield takeLatest(EDIT_VERSION_SAVE_VERSION, saveVersionAsync)
}
function* watchLoadProject() {
  yield takeLatest(EDIT_VERSION_LOAD_PROJECT, loadProjectAsync)
}
function* watchLoadVersion() {
  yield takeLatest(EDIT_VERSION_LOAD_VERSION, loadVersionAsync)
}
function* watchGetPullRequests() {
  yield takeLatest(
    [
      EDIT_VERSION_GET_PULL_REQUESTS,
      EDIT_VERSION_SET_PULL_REQUEST_STATE,
      EDIT_VERSION_ADD_NOTES_FROM_PULL_REQUESTS
    ],
    getPullRequestsAsync
  )
}
function* watchCreateGitHubRelease() {
  yield takeLatest(EDIT_VERSION_CREATE_GITHUB_RELEASE, createGitHubReleaseAsync)
}

export function* rootSaga() {
  yield fork(watchLoadProject)
  yield fork(watchLoadVersion)
  yield fork(watchSaveVersion)
  yield fork(watchGetBranches)
  yield fork(watchGetPullRequests)
  yield fork(watchCreateGitHubRelease)
  yield fork(watchAssignAssetSuccess)
}

// ------------------------------------
// Helpers
// ------------------------------------
function initializeVersion({
  baseVersion,
  channel,
  channelName,
  revision,
  approved,
  project,
  name,
  notes,
  workingBranch,
  prerelease,
  includeParentNotes,
  parentVersion
}) {
  const version = {
    baseVersion: baseVersion,
    version: baseVersion,
    channel,
    revision,
    name,
    status: approved ? 1 : 0,
    projectID: project._id,
    notes,
    workingBranch
  }
  if (version.channel !== Constants.PublicChannel) {
    Object.assign(version, {
      prerelease,
      version: `${baseVersion}-${channelName}.${revision}`,
      channel,
      revision: revision,
      includeParentNotes,
      parentVersion
    })
  }

  return version
}

function* makeUpdateVersionRequest(versionId, version, project) {
  const url = versionId
    ? `/api/project/${project.owner.login}/${project.name}/version/${versionId}`
    : `/api/${project.owner.login}/${project.name}/version`

  const method = versionId ? 'PUT' : 'POST'
  const result = yield call(request, url, {
    method,
    body: JSON.stringify(version)
  })

  Object.assign(result, {
    toastTitle: `${project.owner.login}/${project.name}@${version.version}`,
    toastMessage: versionId ? 'Version updated!' : 'Version created!'
  })

  return result
}

function* confirmResultAndSave(result, project, version) {
  if (result.success) {
    yield put(successToast(result.toastTitle, result.toastMessage))
    yield call(
      loadVersionAsync,
      actions.loadVersion(
        project.owner.login,
        project.name,
        null,
        version.version
      )
    )
    yield put(actions.saveVersionComplete())
    yield put(
      push(`/${project.owner.login}/${project.name}/v/${version.version}`)
    )
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function setAssetTagNames(assets, assetTags) {
  assets.map((a) => {
    a.tag = { _id: a.tag, name: assetTags.find((t) => t._id === a.tag).name }
  })
  return assets
}
