import { connect } from 'react-redux'
import { actions } from '../modules'
import LandingPage from '../components/LandingPage'

const mapDispatchToProps = {
  ...actions
}

const mapStateToProps = (state, ownProps) => {
  const editState = state.get('landing/main').toJS()
  return {
    ...editState,
    ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage)
