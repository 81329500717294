import AssignAssetsModal from '~/containers/AssignAssetsModal'
import AssetGroupItem from '~/components/AssetGroup/AssetGroupItem'
import ProjectHeader from '~/components/ProjectHeader'
import Header from '~/components/Header'
import Icon from '~/components/Icon'
import { StickyContainer, Sticky } from 'react-sticky'
import NoteTag from '~/components/NoteTag'
import PullRequestSelector from './PullRequestSelector'
import React from 'react'
import Octicon from 'react-octicon'
import styles from './EditVersion.scss'
import mdStyles from './VersionNote.scss'
import ReactMarkdown from 'react-markdown'
import NumericInput from 'react-numeric-input'
import { Link } from 'react-router'
import VersionNote from './VersionNote'
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ButtonToolbar
} from 'reactstrap'

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'

import 'react-select/dist/react-select.css'

const SortableVersionNoteHandle = SortableHandle(() => {
  return (
    <div className={'d-none d-sm-block ' + styles.dragHandle}>
      <Icon className="fa fa-bars" />
    </div>
  )
})

const SortableVersionNote = SortableElement(
  ({
    item,
    realIndex,
    tags,
    setNoteText,
    removeNote,
    setNoteTags,
    setNoteAttachment
  }) => {
    return (
      <div className={styles.versionNoteContainer}>
        <SortableVersionNoteHandle />
        <VersionNote
          item={item}
          index={realIndex}
          tags={tags}
          setNoteAttachment={setNoteAttachment}
          setNoteText={setNoteText}
          removeNote={removeNote}
          setNoteTags={setNoteTags}
        />
      </div>
    )
  }
)

const SortableVersionNoteList = SortableContainer(
  ({
    items,
    tags,
    setNoteText,
    setNoteAttachment,
    removeNote,
    setNoteTags
  }) => {
    return (
      <div className="row" style={{ borderTop: '1px solid #ddd' }}>
        {items.map((i, index) => {
          return (
            <SortableVersionNote
              key={`note-${index}`}
              realIndex={index}
              className="sortable-release-note-container"
              index={index}
              item={i}
              tags={tags}
              setNoteText={setNoteText}
              setNoteAttachment={setNoteAttachment}
              removeNote={removeNote}
              setNoteTags={setNoteTags}
            />
          )
        })}
      </div>
    )
  }
)

export default class EditVersion extends React.Component {
  componentDidMount() {
    if (this.props.params && this.props.params.owner) {
      if (this.props.params.versionId) {
        this.props.loadVersion(
          this.props.params.owner,
          this.props.params.name,
          this.props.params.versionId
        )
      } else if (this.props.params.version) {
        this.props.loadVersion(
          this.props.params.owner,
          this.props.params.name,
          undefined,
          this.props.params.version
        )
      } else if (this.props.params.parentVersion) {
        this.props.loadVersion(
          this.props.params.owner,
          this.props.params.name,
          undefined,
          this.props.params.parentVersion,
          true
        )
        this.props.setPreRelease(true)
      } else {
        this.props.loadProject(this.props.params.owner, this.props.params.name)
      }
      this.props.getBranches(this.props.params.owner, this.props.params.name)
    }
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  renderHeader() {
    const versionText =
      this.props.baseVersion !== '0.0.0'
        ? `Version ${this.props.baseVersion}${
            this.props.prerelease
              ? `-${this.props.channelName}.${this.props.revision}`
              : ''
          }`
        : 'New Version'
    return (
      <div className="card" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
        <div className="container-fluid">
          <div
            className="row card-header"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <div className="col-sm-12 col-md-9">
              <h3
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%'
                }}>
                {versionText}
                {this.props.prerelease && (
                  <span className="badge badge-outline-danger mx-2">
                    Pre-release
                  </span>
                )}
              </h3>
            </div>
            <div className="col-sm-12 col-md-3">
              <div
                className="input-group"
                style={{
                  fontSize: '0.8rem',
                  width: '100%',
                  alignSelf: 'center',
                  marginTop: '0.2rem'
                }}>
                <div className="input-group-prepend">
                  <label
                    className="input-group-text"
                    style={{ fontSize: '0.8rem' }}
                    htmlFor="txtWorkingBranch">
                    <Octicon className="ml-2" name="git-branch" />
                  </label>
                </div>
                <select
                  className="custom-select"
                  style={{ background: 'none', backgroundColor: 'white' }}
                  onChange={(e) => {
                    this.props.setWorkingBranch(e.target.value)
                  }}
                  id="txtWorkingBranch"
                  value={this.props.workingBranch}>
                  {this.props.projectBranches.map((bo) => (
                    <option key={`branch-${bo.name}`} value={bo.name}>
                      {bo.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="card-block">
          <div className="container-fluid">
            <div className="row" style={{ marginTop: '1rem' }}>
              <div className="col-md-auto">
                <div className="form-group">
                  <label htmlFor="txtVersion">
                    Version Number - <small>x.y.z</small>
                  </label>
                  <div className="form-inline">
                    <NumericInput
                      readOnly={this.props.prerelease}
                      style={false}
                      className={`${styles.reactNumericInput} form-control`}
                      id="txtVersionMajor"
                      value={this.props.baseVersion.split('.')[0]}
                      onChange={(e) => {
                        const version = `${e}.${
                          this.props.baseVersion.split('.')[1]
                        }.${this.props.baseVersion.split('.')[2]}`
                        this.props.setBaseVersion(version)
                      }}
                    />
                    &nbsp;.&nbsp;
                    <NumericInput
                      readOnly={this.props.prerelease}
                      style={false}
                      className={`${styles.reactNumericInput} form-control`}
                      id="txtVersionMinor"
                      value={this.props.baseVersion.split('.')[1]}
                      onChange={(e) => {
                        const version = `${
                          this.props.baseVersion.split('.')[0]
                        }.${e}.${this.props.baseVersion.split('.')[2]}`
                        this.props.setBaseVersion(version)
                      }}
                    />
                    &nbsp;.&nbsp;
                    <NumericInput
                      readOnly={this.props.prerelease}
                      style={false}
                      className={`${styles.reactNumericInput} form-control`}
                      id="txtVersionMinor"
                      value={this.props.baseVersion.split('.')[2]}
                      onChange={(e) => {
                        const version = `${
                          this.props.baseVersion.split('.')[0]
                        }.${this.props.baseVersion.split('.')[1]}.${e}`
                        this.props.setBaseVersion(version)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-group">
                  <label htmlFor="txtName">Title</label>
                  <input
                    name="txtName"
                    className="form-control"
                    value={this.props.name}
                    onChange={(e) => {
                      this.props.setName(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {this.props.prerelease && (
                <div className="col-auto">
                  <div className="form-group">
                    <label htmlFor="txtChannel">Pre-Release Channel</label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        this.props.setPreReleaseChannel(
                          parseInt(e.target.value)
                        )
                      }
                      value={this.props.channel}
                      disabled={!this.props.canEditChannel}>
                      {this.props.channels.map((c) => (
                        <option key={`channel-${c.value}`} value={c.value}>
                          {c.displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {this.props.prerelease && (
                <div className="col-auto">
                  <div className="form-group">
                    <label htmlFor="txtRevision">Revision</label>
                    <input
                      id="txtRevision"
                      type="text"
                      className="form-control"
                      style={{ width: '70px' }}
                      value={this.props.revision}
                      disabled
                    />
                  </div>
                </div>
              )}
              <div className="col-auto align-self-end mb-3 ml-auto">
                <div>
                  {this.props._id && !this.props.releaseTag && (
                    <button
                      disabled={this.props.creatingRelease}
                      className="btn btn-outline-info"
                      onClick={() => {
                        this.props.createGitHubRelease()
                      }}>
                      {this.props.creatingRelease && (
                        <Icon className="fal fa-fw fa-spin fa-circle-notch" />
                      )}
                      {!this.props.creatingRelease && (
                        <Icon className="fal fa-fw fa-tag" />
                      )}
                      Create Release on GitHub
                    </button>
                  )}
                  {this.props._id && this.props.releaseTag && (
                    <a
                      href={this.props.releaseTag.html_url}
                      target="_blank"
                      role="button"
                      className="btn btn-outline-success">
                      <Icon className="fal fa-fw fa-tag" /> View Release on
                      GitHub
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label>Assets</label>
                {this.renderAssets()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderAssets() {
    if (!this.props._id) {
      return (
        <i className="d-block text-muted">
          You must save a version before you can assign assets to it.
        </i>
      )
    } else {
      if (this.props.assets.length > 0) {
        return (
          <div className="mb-2">
            {this.props.assets.map((a) => (
              <AssetGroupItem
                key={a._id}
                details={a}
                showRemoveButton
                removeConfirmMessage="This will remove the asset from this version."
                onRemove={() => {
                  this.props.removeAssetFromVersion(
                    a,
                    this.props.params.owner,
                    this.props.params.name,
                    {
                      version: this.props.version,
                      baseVersion: this.props.baseVersion,
                      project: this.props.project._id
                    }
                  )
                }}
              />
            ))}
          </div>
        )
      } else {
        return (
          <div className="my-2">
            <button
              onClick={() => {
                this.props.showModal(
                  this.props.params.owner,
                  this.props.params.name,
                  {
                    version: this.props.version,
                    baseVersion: this.props.baseVersion,
                    project: this.props.project._id
                  }
                )
              }}
              className="btn btn-sm btn-outline-success">
              <Icon className="fal fa-plus fa-fw" /> Assign Assets
            </button>
          </div>
        )
      }
    }
  }
  renderNotes() {
    return (
      <div>
        <ul className="nav nav-tabs">
          {this.props.notes.map((n) => {
            const navClassName =
              this.props.locale === n.locale ? 'nav-link active' : 'nav-link'

            return (
              <li className="nav-item" key={n.locale}>
                <a
                  className={navClassName}
                  data-toggle="tab"
                  onClick={() => {
                    this.props.setLocale(n.locale)
                  }}
                  href={`#${n.locale}`}
                  role="tab">
                  {n.locale}
                </a>
              </li>
            )
          })}
        </ul>
        <div className={'tab-content ' + styles.notesContainer}>
          {this.props.notes.map((n) => {
            const navClassName =
              this.props.locale === n.locale ? 'tab-pane active' : 'tab-pane'
            return (
              <div
                className={navClassName}
                style={{ borderBottom: '1px solid #ddd' }}
                key={n.locale}
                id={n.locale}
                role="tabpanel">
                {this.renderLocaleSection(n)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderLocaleSection(locale) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 version-header">
            <label htmlFor={`txtHeader${locale.locale}`}>Version Header</label>
            <div
              style={{
                position: 'relative',
                flex: 1,
                border: '1px solid #ddd'
              }}>
              <ReactMarkdown
                className={mdStyles.markdownRender}
                source={locale.header || '*Add some text here*'}
                softBreak="br"
              />
              <textarea
                className={mdStyles.markdownEditor}
                style={{
                  width: '100%',
                  borderColor: 'transparent',
                  overflow: 'hidden',
                  minHeight: '40px',
                  resize: 'none',
                  outline: 'none',
                  position: 'absolute',
                  padding: '0.5rem',
                  bottom: 0,
                  top: 0,
                  right: 0
                }}
                value={locale.header}
                onChange={(e) => {
                  this.props.setHeader(e.target.value)
                }}
              />
            </div>
            <small style={{ color: '#bbb' }}>
              Provide a summary description of the version. Markdown supported.
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-1" style={{ marginTop: '40px' }}>
            <label>Version Notes</label>
            <button
              role="button"
              style={{ margin: '1rem', display: 'inline-block' }}
              className="btn btn-sm btn-success"
              onClick={this.props.addNote}>
              <Icon className="fal fa-fw fa-plus" /> Add Note
            </button>
            <button
              role="button"
              style={{
                margin: '1rem',
                marginLeft: '0rem',
                display: 'inline-block'
              }}
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.props.togglePullRequestModalVisible()
              }}>
              <Octicon name="git-pull-request" /> Add From Pull Request
            </button>
          </div>
        </div>

        {locale.items.length > 0 ? (
          <SortableVersionNoteList
            onSortEnd={({ oldIndex, newIndex }) => {
              this.props.setLocaleNotes(
                arrayMove(locale.items, oldIndex, newIndex)
              )
            }}
            useDragHandle
            items={locale.items}
            tags={this.props.project.tagList}
            setNoteText={this.props.setNoteText}
            setNoteAttachment={this.props.setNoteAttachment}
            setNoteTags={this.props.setNoteTags}
            removeNote={this.props.removeNote}
          />
        ) : null}

        {this.props.prerelease && (
          <div className="row">
            <div className="col-12 mt-3 mb-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.props.includeParentNotes}
                  id="chkIncludeParentNotes"
                  onChange={(e) => {
                    this.props.setIncludeParentNotes(e.target.checked)
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="chkIncludeParentNotes">
                  Include notes from{' '}
                  <span className="badge badge-secondary">
                    {this.props.baseVersion}
                  </span>
                </label>
              </div>
            </div>
            {this.props.includeParentNotes && (
              <div className="col-12 mb-2">
                <ul className="list-group list-group-flush">
                  {this.props.parentNotes[0].items.map((pn, ni) => {
                    return (
                      <li
                        key={`parent-note-${ni}`}
                        className="list-group-item"
                        style={{
                          padding: '0.5rem',
                          backgroundColor: ni % 2 === 0 ? '#f9f5f7' : 'white'
                        }}>
                        {pn.description}
                        {pn.tags.map((t, ti) => (
                          <NoteTag
                            key={`note-tag-${ti}`}
                            className="mx-2"
                            tag={t}
                          />
                        ))}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  renderPullRequestModal() {
    return (
      <Modal
        style={{
          width: '90vw',
          maxWidth: '700px',
          height: '50vh',
          maxHeight: '600px'
        }}
        className="modal-dialog-centered"
        isOpen={this.props.pullRequests.visible}
        toggle={() => {
          this.props.togglePullRequestModalVisible()
        }}>
        <ModalHeader>Select Pull Requests to add as notes</ModalHeader>
        <ModalBody style={{ padding: '0px' }}>
          <PullRequestSelector
            {...this.props}
            {...this.props.pullRequests}
            setStateFilter={(e) => {
              this.props.setPullRequestStateFilter(e)
            }}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonToolbar style={{ float: 'right' }}>
            <Button
              color="secondary"
              className="mr-2"
              outline
              onClick={() => {
                this.props.togglePullRequestModalVisible()
              }}>
              <Icon className="fal fa-fw fa-times" /> Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                this.props.addNotesFromPullRequests()
              }}>
              <Icon className="fal fa-fw fa-check" /> Add Notes
            </Button>
          </ButtonToolbar>
          <div className="clearfix" />
        </ModalFooter>
      </Modal>
    )
  }

  renderContents() {
    if (this.props.loading || !this.props.project.name) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1 className="display-4">
            <Icon className="fal fa-circle-notch fa-spin" />
          </h1>
        </div>
      )
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-12">{this.renderHeader()}</div>
          </div>
          <div className="row">
            <div className="col-12">{this.renderNotes()}</div>
          </div>
          <div
            className="row"
            style={{ marginTop: '2rem', marginBottom: '5rem' }}>
            <div className="col-12">
              <div className="float-right">
                <Link
                  to={`/${this.props.params.owner}/${this.props.params.name}`}
                  className="btn btn-outline-secondary mr-2"
                  role="button">
                  <Icon className="fal fa-fw fa-times" /> Cancel
                </Link>
                {!this.props.saving && (
                  <button
                    role="button"
                    onClick={this.props.saveVersion}
                    className="btn btn-success">
                    <Icon className="fal fa-fw fa-save" /> Save Version
                  </button>
                )}
                {this.props.saving && (
                  <button role="button" disabled className="btn btn-success">
                    <Icon className="fal fa-fw fa-spin fa-circle-notch" />{' '}
                    Saving...
                  </button>
                )}
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const loading =
      (this.props.params.version && !this.props._id) ||
      (this.props.params.parentVersion && !this.props.parentVersion)
    return (
      <div>
        <StickyContainer
          style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          <Header hideQuote />
          <div>
            <Sticky topOffset={0} relative props={this.props}>
              {({ style, isSticky }) => {
                return (
                  <div
                    style={{
                      zIndex: '1024',
                      backgroundColor: '#f0f0f0',
                      borderBottom: isSticky ? '1px solid #bbb' : '',
                      ...style
                    }}>
                    <ProjectHeader
                      currentPage={this.props.params.version || 'New Version'}
                      isVersion
                      projectName={this.props.params.name}
                      projectOwner={this.props.params.owner}
                    />
                  </div>
                )
              }}
            </Sticky>
            {loading && (
              <div style={{ textAlign: 'center' }}>
                <h1 className="display-4">
                  <Icon className="fal fa-spinner fa-spin" />
                </h1>
              </div>
            )}
            {!loading && (
              <div className="container">{this.renderContents()}</div>
            )}
            <AssignAssetsModal />
          </div>
        </StickyContainer>
        {this.renderPullRequestModal()}
      </div>
    )
  }
}
