// ------------------------------------
// Imports
// ------------------------------------
import { put, fork, call, takeLatest, select } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import request from '~/utils/request'
import { errorToast } from '~/utils/notifications'

// ------------------------------------
// Constants
// ------------------------------------
const ASSETS_LOAD = 'ASSETS::LOAD'
const ASSET_SET_ITEMS = 'ASSETS::SET_ITEMS'
const ASSETS_DELETE_ITEM = 'ASSETS::DELETE_ITEM'
const ASSETS_DELETE_ITEM_SUCCESS = 'ASSETS::DELETE_ITEM_SUCCESS'

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  deleteAsset: (projectId, commitHash, assetId) => {
    return {
      type: ASSETS_DELETE_ITEM,
      projectId,
      commitHash,
      assetId
    }
  },
  deleteAssetSuccess: (owner, name) => {
    return {
      type: ASSETS_DELETE_ITEM_SUCCESS,
      owner,
      name
    }
  },
  loadAssets: (owner, name) => {
    return {
      type: ASSETS_LOAD,
      owner,
      name
    }
  },
  setAssets: (assets, enableAssetTags) => {
    return {
      type: ASSET_SET_ITEMS,
      assets,
      enableAssetTags
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ASSETS_LOAD]: (state, action) => {
    return state
      .set('loading', true)
      .set('owner', action.owner)
      .set('name', action.name)
  },
  [ASSET_SET_ITEMS]: (state, action) => {
    return state
      .set('loading', false)
      .set('assets', fromJS(action.assets))
      .set('enableAssetTags', action.enableAssetTags)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  assets: [],
  enableAssetTags: false
}
export default function Reducer(state = fromJS(initialState), action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
function* loadAssetsAsync(action) {
  var result = null

  result = yield call(request, `/api/${action.owner}/${action.name}/assets`)

  if (result.success) {
    yield put(actions.setAssets(result.assets, result.enableAssetTags))
  } else {
    if (result.message) {
      yield put(errorToast('Oops!', result.message))
    }
  }
}

function* deleteAssetAsync(action) {
  try {
    const requestOpts = {
      method: 'DELETE',
      body: JSON.stringify({
        commitHash: action.commitHash,
        assetId: action.assetId
      })
    }

    const result = yield call(
      request,
      `/api/projects/${action.projectId}/asset`,
      requestOpts
    )

    if (result.success === true) {
      const assetsState = yield select((state) =>
        state.get('versions/assets').toJS()
      )
      yield put(actions.deleteAssetSuccess(assetsState.owner, assetsState.name))
    } else {
      if (result.message) {
        yield put(errorToast('Oops!', result.message))
      }
    }
  } catch (e) {
    yield put(
      errorToast(
        'Oops!',
        'Something unexpected happened while removing the asset.'
      )
    )
    console.error(e)
  }
}

function* watchLoadAssets() {
  yield takeLatest([ASSETS_LOAD, ASSETS_DELETE_ITEM_SUCCESS], loadAssetsAsync)
}

function* watchDeleteAsset() {
  yield takeLatest(ASSETS_DELETE_ITEM, deleteAssetAsync)
}

export function* rootSaga() {
  yield fork(watchLoadAssets)
  yield fork(watchDeleteAsset)
}
