import Icon from '~/components/Icon'
import PullRequest from './PullRequest'
import React from 'react'
export default class PullRequestSelector extends React.PureComponent {
  render() {
    const stateOptions = [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'Closed',
        value: 'closed'
      },
      {
        label: 'Open',
        value: 'open'
      }
    ]
    const selectedState = stateOptions.find((o) => o.value === this.props.state)
    return (
      <div className="card">
        <div className="card-header input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Icon className="fal fa-fw fa-search" />
            </span>
          </div>
          <input
            type="text"
            value={this.props.prFilter}
            onChange={(e) => {
              this.props.setPRFilter(e.target.value)
            }}
            className="form-control"
            placeholder="Search pull requests..."
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <Icon className="fal fa-filter fa-fw" /> {selectedState.label}
            </button>
            <div className="dropdown-menu">
              {stateOptions.map((o) => {
                return (
                  <a
                    className={`dropdown-item ${
                      o.value === selectedState.value ? 'active' : ''
                    }`}
                    role="button"
                    onClick={(e) => {
                      this.props.setStateFilter(o.value)
                    }}>
                    {o.label}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div style={{ height: '50vh', overflowY: 'auto' }}>
          <ul className="list-group list-group-flush">
            {this.props.items.map((pr, prIndex) => {
              if (
                this.props.prFilter.trim() === '' ||
                pr.title
                  .toLowerCase()
                  .indexOf(this.props.prFilter.trim().toLowerCase()) > -1
              ) {
                return (
                  <PullRequest
                    key={pr.id}
                    {...pr}
                    setSelected={(val) => {
                      this.props.setPullRequestSelected(prIndex, val)
                    }}
                  />
                )
              }
            })}
          </ul>
        </div>
      </div>
    )
  }
}
