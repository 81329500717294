import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import React from 'react'
import { Link } from 'react-router'
import styles from './ProjectHeader.scss'

export default class ProjectHeader extends React.PureComponent {
  getLinkClasses (linkName) {
    return classNames('hover-success mx-1 px-2', {
      'text-muted': linkName !== this.props.currentPage,
      'text-info font-weight-bold': linkName === this.props.currentPage
    })
  }

  render () {
    return (<div className='container' >
      <div className='row justify-content-between align-items-center py-3' >
        <div className='col-12 col-md-auto'>
          <nav aria-label='breadcrumb'>
            <ol className={classNames('breadcrumb', styles.projectBreadcrumb)}>
              <li className='breadcrumb-item'>
                <Link className='nav-link' style={{ padding: 0, display: 'inline' }} to='/'>
                  <Icon className='fal fa-home fa-fw d-md-none' />
                  <span className='d-none d-md-inline-block'>projects</span>
                </Link>
              </li>
              <li className='breadcrumb-item'>
                <Link className='nav-link'
                  style={{ padding: 0, display: 'inline' }}
                  to={`/${this.props.projectOwner}/${this.props.projectName}`}>
                  <span className='d-none d-md-inline-block'>{this.props.projectOwner}/</span>{this.props.projectName}
                </Link>
              </li>
              {this.props.isVersion &&
              <li className='breadcrumb-item'>
                <Link className='nav-link'
                  style={{ padding: 0, display: 'inline' }}
                  to={`/${this.props.projectOwner}/${this.props.projectName}`}>
                  versions
                </Link>
              </li>
              }
            </ol>
          </nav>
        </div>
        {!this.props.isVersion && <div className='col-auto'>
          <Link title='Versions'
            className={this.getLinkClasses('Versions')}
            to={`/${this.props.projectOwner}/${this.props.projectName}/`}>
            <Icon className='fal fa-code-branch' /> Versions
          </Link>
          <Link title='Assets'
            className={this.getLinkClasses('Assets')}
            to={`/${this.props.projectOwner}/${this.props.projectName}/assets`}>
            <Icon className='fal fa-file-archive' /> Assets
          </Link>
          <Link title='Settings'
            className={this.getLinkClasses('Settings')}
            to={`/${this.props.projectOwner}/${this.props.projectName}/settings`}>
            <Icon className='fal fa-cog' /> Settings
          </Link>
        </div>}
      </div>
    </div>)
  }
}

ProjectHeader.propTypes = {
  currentPage: PropTypes.string
}
