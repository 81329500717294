import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class VersionNumberBadge extends React.PureComponent {
  render () {
    const classes = classNames('badge', {
      'hover-danger badge-outline-danger': this.props.version.prerelease,
      'badge-success': !this.props.version.prerelease
    })
    return (
      <span className={classes}>
        {this.props.version.version}
      </span>
    )
  }
}

VersionNumberBadge.propTypes = {
  version: PropTypes.shape({
    prerelease: PropTypes.bool,
    version: PropTypes.string.isRequired
  }).isRequired
}
