import '@babel/polyfill'
import tether from 'tether'
import 'bootstrap'

import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory } from 'react-router'
import createStore from './store/createStore'
import AppContainer from './containers/App'
import { selectLocationState } from './containers/App/selectors'
import { syncHistoryWithStore } from 'react-router-redux'

global.Tether = tether
// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState, browserHistory)

// ========================================================
// Render Setup
// ========================================================

let MOUNT_NODE

let render = () => {
  const routes = require('./routes/index').default(store)
  const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: selectLocationState()
  })
  ReactDOM.render(
    <AppContainer store={store} history={history} routes={routes} />,
    MOUNT_NODE
  )
}

//window.setTimeout(() => {
MOUNT_NODE = document.getElementById('root')
render()
//}, 50)

// // This code is excluded from production bundle
// if (__DEV__) {
//   if (module.hot) {
//     // Development render functions
//     const renderApp = render
//     const renderError = (error) => {
//       const RedBox = require('redbox-react').default

//       ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
//     }

//     // Wrap render in try/catch
//     render = () => {
//       try {
//         renderApp()
//       } catch (error) {
//         console.error(error)
//         renderError(error)
//       }
//     }

//     // Setup hot module replacement
//     module.hot.accept('./routes/index', () =>
//       setImmediate(() => {
//         ReactDOM.unmountComponentAtNode(MOUNT_NODE)
//         render()
//       })
//     )
//   }
// }

// ========================================================
// Go!
// ========================================================

// render()
