import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AssetItem from '~/components/AssetItem'

export default class SingleAssetSelectionList extends React.PureComponent {
  render() {
    return (
      <div style={{ height: '50vh', overflowY: 'auto' }}>
        <ul className="list-group">
          {this.props.items.map(this.renderSingleAssetItem, this)}
        </ul>
      </div>
    )
  }

  renderSingleAssetItem(asset) {
    if (!Array.isArray(asset.assets) || asset.assets.length === 0) {
      return null
    }

    const selected =
      this.props.selectedItem &&
      this.props.selectedItem.commitHash === asset.commitHash

    const itemClasses = classNames('list-group-item list-group-item-action', {
      active: selected
    })

    return (
      <li
        key={asset.commitHash}
        className={itemClasses}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          if (e.target.tagName.toLowerCase() !== 'a') {
            this.props.setSelected(asset)
          }
        }}>
        <AssetItem
          asset={asset}
          projectOwner={this.props.projectOwner}
          projectName={this.props.projectName}
        />
      </li>
    )
  }
}

SingleAssetSelectionList.propTypes = {
  items: PropTypes.array.isRequired,
  projectOwner: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  setSelected: PropTypes.func.isRequired
}
