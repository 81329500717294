import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Icon extends React.PureComponent {
  render() {
    const faTypeRegex = /fa[a-z]?/
    const faIconRegex = /^fa-/

    const oldClassNameParts = this.props.className.split(' ')
    const iconParts = []
    const classNameParts = []
    oldClassNameParts.map((cp, i) => {
      if (faTypeRegex.test(cp) || faIconRegex.test(cp)) {
        iconParts.push(cp.replace(/^fa-/g, ''))
      } else {
        classNameParts.push(cp)
      }
    })

    // let spin = false
    // let fixedWidth = false
    // for (var i = 0; i < iconParts.length; i++) {
    //   const x = iconParts[i]
    //   if (x === 'spin') {
    //     iconParts.splice(i, 1)
    //     i--
    //     spin = true
    //   }
    //   if (x === 'fw') {
    //     iconParts.splice(i, 1)
    //     i--
    //     fixedWidth = true
    //   }
    // }
    return <i className={this.props.className} />
  }
}
