import SecureRoute from '../../SecureRoute'
import { injectReducer } from '../../../store/reducers'
import { injectSaga } from '../../../store/sagas'
const EditVersionContainer = require('./containers/EditVersionContainer')
  .default
const reducer = require('./modules').default
const saga = require('./modules').rootSaga
// Sync route definition
export default class EditVersionRoute extends SecureRoute {
  constructor(store, path) {
    super(store)
    this.path = path
    this.getComponent = (nextState, cb) => {
      /*  Add the reducer to the store on key 'counter'  */
      injectReducer(store, {
        key: 'versions/edit',
        reducer
      })

      // /*  Run the rootSaga on the sagaMiddleware with key of 'counter'  */
      injectSaga('versions/edit', saga)

      cb(null, EditVersionContainer)
    }
  }
}
