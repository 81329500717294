import fetch from 'isomorphic-fetch'
import cookieManager from './cookies'
import { errorToast } from './notifications'

let store

const expectedStatusCodes = [
  200,
  400,
  401,
  403,
  404
]

export function setStore (storeInstance) {
  store = storeInstance
}

export default (url, options) => {
  if (!options) {
    options = {}
  }
  if (!options.headers) {
    options.headers = {}
  }
  const token = cookieManager.readCookie('accessToken')
  if (token) {
    options.headers.Authorization = `Bearer ${token}`
  }
  options.headers['Content-Type'] = 'application/json'
  return fetch(url, { credentials: 'include', ...options })
    .then((result) => {
      if (expectedStatusCodes.indexOf(result.status) === -1 && store && store.dispatch) {
        store.dispatch(errorToast('Oops!', 'Congratulations, you\'ve discovered an error we weren\'t expecting. ' +
          'Things should return to normal soon so try again in a little bit.'))
      }

      if (options.format === 'html') {
        return result.text()
      } else {
        return result.json()
      }
    })
}
