import SecureRoute from '../../SecureRoute'
import { injectReducer } from '../../../store/reducers'
import { injectSaga } from '../../../store/sagas'

export default class AssetsRoute extends SecureRoute {
  constructor(store, path) {
    super(store)
    this.path = path
    this.getComponent = async (nextState, cb) => {
      const AssetsContainer = await import('./containers/AssetsContainer')
      const AssetsModule = await import('./modules')

      /*  Add the reducer to the store on key */
      injectReducer(store, {
        key: 'versions/assets',
        reducer: AssetsModule.default
      })

      // /*  Run the rootSaga on the sagaMiddleware with key */
      injectSaga('versions/assets', AssetsModule.rootSaga)

      cb(null, AssetsContainer.default)
    }
  }
}
