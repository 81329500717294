import classNames from 'classnames'
import React from 'react'
import styles from './Ribbon.scss'

export default class Ribbon extends React.PureComponent {
  render() {
    const ribbonClasses = classNames(styles.ribbon, this.props.className)
    return (
      <div className={ribbonClasses}>
        <div className={styles.ribbonContent}>{this.props.children}</div>
      </div>
    )
  }
}
