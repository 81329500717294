import createSagaMiddleware from 'redux-saga'
import { rootSaga as assignAssetsSaga } from '../containers/AssignAssetsModal/reducer'

const sagaRegistry = {}

export const sagaMiddleware = createSagaMiddleware()

export const injectSaga = (key, saga) => {
  if (sagaRegistry[key]) {
    sagaRegistry[key].cancel()
  }
  sagaRegistry[key] = sagaMiddleware.run(saga)
}

export const initializeSagas = () => {
  injectSaga('assignAssets', assignAssetsSaga)
}
