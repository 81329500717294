import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'

export default function globalReducer (state = globalInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        route: action.payload
      })
    case 'GLOBAL_SET_USER':
      return state.set('user', fromJS(action.user))
  }
  return state
}

const globalInitialState = fromJS({
})
