import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router'
import Icon from '~/components/Icon'

export default class AssetGroupItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { deleteMode: false }
  }

  _showDeleteMode() {
    this.setState({ deleteMode: true })
  }

  _hideDeleteMode() {
    this.setState({ deleteMode: false })
  }

  render() {
    return (
      <li
        className="row"
        style={{ listStyle: 'none', lineHeight: 2, fontSize: '0.875em' }}>
        <div className="col-3 text-muted">
          <Icon className="fal fa-file-code mr-2" />
          {this.props.details.tag.name === '[default]'
            ? 'Default'
            : this.props.details.tag.name}
        </div>
        {this.state.deleteMode ? this.renderDeleteMode() : this.renderDetails()}
      </li>
    )
  }

  renderDetails() {
    return (
      <div className="col-9">
        <div className="mx-2 float-left text-muted">
          <Link
            to={this.props.details.binaryUrl}
            className="text-info"
            target="_blank">
            {this.props.details.name === '[default]' ||
            this.props.details.name === ''
              ? 'Download Binary'
              : this.props.details.name}
          </Link>
        </div>
        {this.props.showRemoveButton && (
          <div className="float-right">
            <button
              onClick={() => this._showDeleteMode()}
              className="btn btn-sm btn-outline-danger dim-button">
              <Icon className="fal fa-trash fa-fw" />
            </button>
          </div>
        )}
        <div className="float-right mr-2 text-muted">
          {moment(this.props.details.createdDate).format('llll')}
        </div>
      </div>
    )
  }

  renderDeleteMode() {
    const confirmMessage =
      this.props.removeConfirmMessage ||
      'This will permanently remove this asset.'
    return (
      <div className="col-9" style={{ fontSize: '12px' }}>
        <span>
          <strong className="text-danger">Are you sure?</strong>{' '}
          {confirmMessage}
        </span>
        <div className="float-right">
          <button
            className="btn btn-sm btn-danger mr-1"
            role="button"
            onClick={this.props.onRemove}>
            Remove Asset
          </button>
          <button
            className="btn btn-sm btn-outline-secondary"
            role="button"
            onClick={() => this._hideDeleteMode()}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

AssetGroupItem.propTypes = {
  details: PropTypes.object,
  onRemove: PropTypes.func,
  removeConfirmMessage: PropTypes.string,
  showRemoveButton: PropTypes.bool
}
