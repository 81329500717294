import AssignAssetsModal from '~/containers/AssignAssetsModal'
import Icon from '~/components/Icon'
import React from 'react'
import ProjectHeader from '~/components/ProjectHeader'
import VersionNumberBadge from '~/components/VersionNumberBadge'
import VersionDropdown from './VersionDropdown'
import Header from '~/components/Header'
import { Link, browserHistory } from 'react-router'
import moment from 'moment'
import { StickyContainer, Sticky } from 'react-sticky'

export default class Versions extends React.Component {
  componentDidMount() {
    if (this.props.params && this.props.params.owner) {
      this.props.loadVersions(this.props.params.owner, this.props.params.name)
    }
  }

  componentWillUnmount() {
    this.props.setFilter('')
  }

  renderVersions() {
    if (this.props.loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1 className="display-4">
            <Icon className="fal fa-circle-notch fa-spin" />
          </h1>
        </div>
      )
    } else {
      if (
        this.props.versions &&
        this.props.versions.length > 0 &&
        this.props.params.owner &&
        this.props.params.name
      ) {
        return (
          <div style={{ paddingBottom: '200px' }}>
            <Sticky topOffset={0} relative props={this.props}>
              {({ style, isSticky }) => {
                return (
                  <div
                    style={{
                      zIndex: '1024',
                      backgroundColor: '#f0f0f0',
                      borderBottom: isSticky ? '1px solid #bbb' : '',
                      ...style
                    }}>
                    <ProjectHeader
                      currentPage="Versions"
                      projectName={this.props.params.name}
                      projectOwner={this.props.params.owner}
                    />
                    <div className="container pb-2">
                      <div className="row">
                        <div
                          className="col-12 col-sm"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}>
                          <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <Icon className="fal fa-filter" />
                              </span>
                            </div>
                            <input
                              value={this.props.versionFilter}
                              type="text"
                              className="form-control"
                              autoFocus
                              onChange={(e) => {
                                this.props.setFilter(e.target.value)
                              }}
                              id="inlineFormInputGroup"
                              placeholder="Filter versions..."
                            />
                          </div>
                        </div>
                        <div
                          className="col-12 col-sm-auto"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}>
                          <Link
                            to={`/${this.props.params.owner}/${
                              this.props.params.name
                            }/versions/create`}
                            role="button"
                            className="btn btn-success">
                            New Version
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            </Sticky>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <table
                    className="table table-hover table-condensed "
                    style={{ marginTop: '20px', border: '1px solid #dee2e6' }}>
                    <thead>
                      <tr>
                        <th>Version</th>
                        <th>Created</th>
                        <th style={{ textAlign: 'center', width: '4rem' }}>
                          Notes
                        </th>
                        <th style={{ textAlign: 'center', width: '4rem' }} />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.versions.map((r) => {
                        if (
                          this.props.versionFilter.trim() === '' ||
                          r.version.indexOf(this.props.versionFilter.trim()) >
                            -1
                        ) {
                          return (
                            <tr
                              style={{
                                cursor: 'pointer',
                                alignItems: 'center'
                              }}
                              key={r._id}
                              className="text-muted"
                              onClick={(e) => {
                                if (e.target.nodeName.toLowerCase() === 'td') {
                                  browserHistory.push(
                                    `/${this.props.params.owner}/${
                                      this.props.params.name
                                    }/v/${r.version}`
                                  )
                                }
                              }}>
                              <td className="d-xs-table-cell va-middle">
                                <Link
                                  className={r.prerelease ? 'ml-3' : ''}
                                  to={`/${this.props.params.owner}/${
                                    this.props.params.name
                                  }/v/${r.version}`}>
                                  <VersionNumberBadge version={r} />
                                </Link>
                                <span className="small ml-2 d-none d-md-inline-block">
                                  {r.name ? ` ${r.name}` : ' No title'}
                                </span>
                              </td>
                              <td
                                className="text-muted  va-middle d-none d-md-table-cell"
                                style={{ fontSize: '0.8rem' }}>
                                {moment(r.createdDate).format('llll')}
                              </td>
                              <td
                                className="text-muted  va-middle d-xs-table-cell d-md-none"
                                style={{ fontSize: '0.8rem' }}>
                                {moment(r.createdDate).format('ll')}
                              </td>
                              <td className=" va-middle text-center">
                                {r.notes[0].items.length}
                              </td>
                              <td className="va-middle text-center">
                                <VersionDropdown
                                  version={r}
                                  showAssignAssetsModal={
                                    this.props.showAssignAssetsModal
                                  }
                                  projectOwner={this.props.params.owner}
                                  projectName={this.props.params.name}
                                />
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div style={{ backgroundColor: '#f0f0f0' }}>
              <ProjectHeader
                currentPage="Versions"
                projectName={this.props.params.name}
                projectOwner={this.props.params.owner}
              />
            </div>
            <div className="p-4 text-center">
              <div className="lead">
                <span className="text-info">{this.props.project.name}</span>{' '}
                doesn't have any versions yet.
              </div>
              <Link
                to={`/${this.props.params.owner}/${
                  this.props.params.name
                }/versions/create`}
                role="button"
                className="btn btn-success mt-3">
                Create New Version
              </Link>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    return (
      <StickyContainer
        style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        <div>
          <Header />
          {this.renderVersions()}
          <AssignAssetsModal />
        </div>
      </StickyContainer>
    )
  }
}
