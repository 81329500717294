import { injectReducer } from '../../../store/reducers'
import { injectSaga } from '../../../store/sagas'

import Home from './containers/HomeContainer'
import { default as reducer, rootSaga } from './modules'

export default class DocumentationRoute {
  constructor(store, path) {
    this.path = path

    this.getComponents = async (nextState, cb) => {
      injectReducer(store, {
        key: 'documentation',
        reducer: reducer
      })

      injectSaga('documentation', rootSaga)
      cb(null, Home)
    }
  }
}
